<app-splashpage-risultati [nomeAzienda]="nomeAzienda" [smartBp]="smartBp" *ngIf="this.sintesi">
</app-splashpage-risultati>


<app-dettaglio-risultati *ngIf="this.dettaglio" [currentBp]="praticaBp" [smartBp]="smartBp">
</app-dettaglio-risultati>

<!--
<app-categorie [nomeAzienda]="nomeAzienda" *ngIf="this.dossier">
</app-categorie>
-->

<app-crea-dossier *ngIf="this.dossier">
</app-crea-dossier>