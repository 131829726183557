import { HttpClient } from "@angular/common/http";
import { MatIconModule } from '@angular/material/icon';
import { Component, OnInit } from "@angular/core";
import { DatePipe, Location } from '@angular/common';
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DatiQuestionarioService } from '../_services/dati-questionario.service';
import { saveDettaglio } from "../risultati-questionario/interface/dettaglio";
import { saveVolatileSplashpage } from "../risultati-questionario/interface/save-splashpage-data";
import { PraticheService } from "../_services/pratiche.service";
import { TokenStorageService } from "../_services/token-storage.service";
import { MenuPulsantieraService } from "./services/menu-pulsantiera.service";
import { ModalcontentComponent } from "../modalcontent/modalcontent/modalcontent.component";
import { ConfigurazioneBusinessPlanService } from 'src/app/_services/configurazione-business-plan.service';
import { QuestionarioBp } from "../interface/questionario-bp";
import { PraticheNuova } from './../interface/Pratiche-nuova';
import { DettaglioRisultatiService } from "../_services/dettaglio-risultati.service";
import { SplashpageServiceService } from "../_services/splashpage-service.service";
import { Subscription } from 'rxjs';
import { QuestionarioBpTot } from './../interface/QuestionarioBpTot';
import { RisultatiComplessivo } from "../risultati-questionario/interface/risultatiComplessivi";
import { PDFserviceService } from "../_services/pdfservice.service";
import { SmartBpService } from "../_services/smart-bp.service";
import { Richiedente } from "../interface/richiedente";


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
    // non attivare. lheader deve sempre ascoltare
    /*     risultatiVolatileSubscription: Subscription
        questionarioBpSubscription: Subscription
        bpSuscription: Subscription
        navigateSintesiSuscription: Subscription
        navigateDossierSuscription: Subscription
        navigateDettaglioSuscription: Subscription */

    public href: string = "";
    currentUser: any;
    anagraficaCliente: any;
    dataTosave: saveVolatileSplashpage = {
        idBusinessPlan: 0,
        sintesi: null,
        asis: null,
        complessiva: null,
        anno: [],
        picchi: null
    };
    dettaglioToSave: saveDettaglio = {
        idBusinessPlan: 0,
        annoInizio: 0,
        numeroSimulazioni: 0,
        idLineeBusinessBp: null,
        complessiva: null,
        indicatori: null,
        debitoria: null,
        contoeconomico: null,
        cashflow: null,
        patrimoniale: null,
        debitoFinanziario: null,
        mcc: null,
        garanzie: null,
        indiciBilancio: null,
        istogrammaBilancio: null,
        crisiImpresa: null,
        commento: ''
    };
    formQuestionario = null;
    dataBusinessPlan = null;
    definitivo: any;
    riferimentoOld: any;
    idBusinessPlan: any;
    fileUpload: any;
    nomeSalvataggio: string;
    riferimentoBpOld: PraticheNuova;
    questionario: QuestionarioBp;
    requestBp: PraticheNuova
    isRisultatiVolatile: boolean;
    commentoExp: string = '';

    data = new Date();


    dettaglio: boolean = false;
    sintesi: boolean = true;
    dossier: boolean = false;
    smartBp = false;
    richiedenteSmart: Richiedente = null;

    constructor(
        private tokenStorageService: TokenStorageService,
        private _location: Location,
        private headerService: MenuPulsantieraService,
        private configurazioneBpService: ConfigurazioneBusinessPlanService,
        private router: Router,
        private pulsantieraHeader: MenuPulsantieraService,
        private dettService: DettaglioRisultatiService,
        private splashService: SplashpageServiceService,
        private httpClient: HttpClient,
        private modalService: NgbModal,
        private pdfService: PDFserviceService,
        private smartBpService: SmartBpService) { }

    ceckIfShowBackArrow(): boolean {
        return !(this.router.url.includes('/login') || (this.router.url.includes('/funzioni')) || (!!this.tokenStorageService.getUser() && !!this.tokenStorageService.getUser().roles   && this.tokenStorageService.getUser().roles.id == 6) || (this.smartBp));
    }

    ngOnInit(): void {

        //RIVEDERE
        this.smartBpService.currentRichiedenteSmartBp.subscribe(ric => {
if(ric != null && ric.id!=0) {
    this.smartBp = true;
    this.richiedenteSmart = ric;
    
}else{
    this.smartBp = false;
    this.richiedenteSmart = null;
}
        });
        this.dettService.risultatiCommento$.subscribe(comm => {
            if (comm != null) this.commentoExp = comm;
        })
        this.pulsantieraHeader.NAVIGATE_DETTAGLIO$.subscribe(dettaglio => {
            this.dettaglio = dettaglio;
        })

        this.pulsantieraHeader.NAVIGATE_SINTESI$.subscribe(sintesi => {
            this.sintesi = sintesi;
        })

        this.pulsantieraHeader.NAVIGATE_DOSSIER$.subscribe(dossier => {
            this.dossier = dossier;
        })

        this.href = this.router.url;

        this.currentUser = this.tokenStorageService.getUser();
        /* chiamata dati cliente */
        /* this.httpClient.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/GetAnagraficaClienteById', { idCliente: this.currentUser.cliente.idCliente }).subscribe(
          response => {
            console.log(response)
            this.anagraficaCliente = response;
          }
        ) */

        this.configurazioneBpService.currentBusinessPlan.subscribe(bp => {
            if (bp != null) {
                this.riferimentoBpOld = bp
            }else{
                this.riferimentoBpOld = null;
            }
        })
        this.configurazioneBpService.currentQuestionarioBp.subscribe(response => {
            if (response != null) {
                this.questionario = response;
            } else {
                this.questionario = null;
            }
        });

        this.pulsantieraHeader._richiesta_di_save$.subscribe(
            dataToSave => {
                if (dataToSave != null) {
                    this.dataTosave = dataToSave;
                }
            }
        )

        this.pulsantieraHeader._richiesta_di_save_dettaglio$.subscribe(
            dataToSave => {
                if (dataToSave != null) {
                    this.dettaglioToSave = dataToSave;
                }
            }
        )


        this.configurazioneBpService.currentRisultatiVolatile.subscribe(response => {
            this.isRisultatiVolatile = response;
        })

    }

    ngDoCheck(): void {
        this.href = this.router.url;
    }

    salvaQuestionario(conferma: boolean) {
        /* se questionario è vuoto lo passo vuoto e lo carico a BE */
        //if (this.isRisultatiVolatile) {
        this.requestBp = {
            id: this.nomeSalvataggio == this.riferimentoBpOld.riferimentoBp ? this.riferimentoBpOld.id : 0, //BusinessPlan
            idUtente: this.tokenStorageService.getUser().idUtente,
            riferimentoBp: this.nomeSalvataggio,
            idAziendaRichiedente: this.riferimentoBpOld.idAziendaRichiedente,
            nomeAziendaRichiedente: this.riferimentoBpOld.nomeAziendaRichiedente,
            dataCreazione: this.nomeSalvataggio == this.riferimentoBpOld.riferimentoBp ? this.riferimentoBpOld.dataCreazione : this.data,
            dataModifica: this.data,
            //Rivedere
            idStato: conferma ? 3 : 2,
            idContoEconomico: this.riferimentoBpOld.idContoEconomico,
            idStatoPatrimonialeAttivo: this.riferimentoBpOld.idStatoPatrimonialeAttivo,
            idStatoPatrimonialePassivo: this.riferimentoBpOld.idStatoPatrimonialePassivo,
            bilancioAbbreviato: this.riferimentoBpOld.bilancioAbbreviato,
        }/* manteniamo gli stessi id */
        let questionario: QuestionarioBpTot = {
            questionarioCompleto: this.questionario,
            riferimentoBp: this.requestBp,
        }
        if (conferma) {
            if (this.modalService.hasOpenModals) {
                this.modalService.dismissAll(); /*chiude tutto per sicurezza? */
            } const modalRef = this.modalService.open(ModalcontentComponent);
            modalRef.componentInstance.modalPar = {
                name: "confermaBp",
                title: "Conferma Business Plan",
                description: "Sicuro di voler salvare definitivamente il business plan?" + '\n' + " In questo modo non sarà più possibile modificarlo.",
                actionButtonText: "Salva definitivo",
                undoButtonText: "Annulla",
            }
            modalRef.result.then(r => {
                if (r) {
                    this.configurazioneBpService.saveBusinessPlanLavorazione(questionario, this.riferimentoBpOld.id).subscribe((id) => {
                        if (id != null) {
                            this.dataTosave.idBusinessPlan = id;
                            this.dettaglioToSave.idBusinessPlan = id;
                            this.dettaglioToSave.commento = this.commentoExp;
                            let idLineeBusiness = [];
                            questionario.questionarioCompleto.ricaviCosti.forEach(lineaCompleta => {
                                idLineeBusiness.push(lineaCompleta.linea.id);
                            })
                            this.dettaglioToSave.idLineeBusinessBp = idLineeBusiness;

                            let risultatiTot: RisultatiComplessivo = {
                                idBpOld: this.riferimentoBpOld.id,        
                                splashPage: this.dataTosave,
                                dettaglio: this.dettaglioToSave
                            };
                            this.pulsantieraHeader.clickOnSave(risultatiTot).subscribe(
                                response => {
                                    if (response) {
                                        this.configurazioneBpService.changeBpSalvato(true)
                                        this.configurazioneBpService.isRisultatiVolatile(false)
                                    }
                                    if (this.modalService.hasOpenModals) {
                                        this.modalService.dismissAll(); /*chiude tutto per sicurezza? */
                                    }
                                    const modalRef = this.modalService.open(ModalcontentComponent, {
                                        backdrop: 'static',
                                        backdropClass: 'customBackdrop',
                                    });
                                    modalRef.componentInstance.modalPar = {
                                        name: response == true ? "messaggiosalva" : "messaggioerrore",
                                        description: response == true ? "Business Plan salvato con successo!" : "Errore nel salvataggio",
                                        undoButtonText: "Ok",
                                    }
                                    modalRef.result.then(r => {
                                        if (response == true && this.requestBp.id == 0) {
                                            this.router.navigate(['/funzioni'])
                                        } else if (response == false) {
                                            this.configurazioneBpService.deleteBusinessPlan(id, questionario.riferimentoBp.idStato)
                                        }
                                    });

                                }

                            );

                        } else {
                            if (this.modalService.hasOpenModals) {
                                this.modalService.dismissAll(); /*chiude tutto per sicurezza? */
                            }
                            const modalRef = this.modalService.open(ModalcontentComponent, {
                                backdrop: 'static',
                                backdropClass: 'customBackdrop',
                            });
                            modalRef.componentInstance.modalPar = {
                                name: "messaggioerrore",
                                description: "Errore nel salvataggio",
                                undoButtonText: "Ok",
                            }
                        }
                    });
                }
            })
        } else {
            this.configurazioneBpService.saveBusinessPlanLavorazione(questionario, this.riferimentoBpOld.id).subscribe((id) => {
                if (id != null) {
                    this.dataTosave.idBusinessPlan = id;
                    this.dettaglioToSave.idBusinessPlan = id;
                    this.dettaglioToSave.commento = this.commentoExp;
                    //RIVEDERE; IL SALVATAGGIO DEI RISULTATI PER LDB HA L'ID SBAGLIATO
                    if(questionario && questionario.questionarioCompleto){
                    let idLineeBusiness = [];
                    questionario.questionarioCompleto.ricaviCosti.forEach(lineaCompleta => {
                        idLineeBusiness.push(lineaCompleta.linea.id);
                    })
                    this.dettaglioToSave.idLineeBusinessBp = idLineeBusiness;
                    }
                    let risultatiTot: RisultatiComplessivo = {
                        idBpOld: this.riferimentoBpOld.id,
                        splashPage: this.dataTosave,
                        dettaglio: this.dettaglioToSave
                    };
                    this.pulsantieraHeader.clickOnSave(risultatiTot).subscribe(
                        response => {
                            if (response) {
                                this.configurazioneBpService.changeBpSalvato(response)
                                this.configurazioneBpService.isRisultatiVolatile(!response)
                            }
                            if (this.modalService.hasOpenModals) {
                                this.modalService.dismissAll(); /*chiude tutto per sicurezza? */
                            }
                            const modalRef = this.modalService.open(ModalcontentComponent, {
                                backdrop: 'static',
                                backdropClass: 'customBackdrop',
                            });
                            modalRef.componentInstance.modalPar = {
                                name: response == true ? "messaggiosalva" : "messaggioerrore",
                                description: response == true ? "Business Plan salvato con successo!" : "Errore nel salvataggio",
                                undoButtonText: "Ok",
                            }
                            modalRef.result.then(r => {
                                if (response == true && this.requestBp.id == 0) {
                                    this.router.navigate(['/listaBplanNuova'])
                                } else if (response == false) {
                                    this.configurazioneBpService.deleteBusinessPlan(id, questionario.riferimentoBp.idStato)
                                }
                            });
                        }
                    );

                } else {
                    if (this.modalService.hasOpenModals) {
                        this.modalService.dismissAll(); /*chiude tutto per sicurezza? */
                    }
                    const modalRef = this.modalService.open(ModalcontentComponent, {
                        backdrop: 'static',
                        backdropClass: 'customBackdrop',
                    });
                    modalRef.componentInstance.modalPar = {
                        name: "messaggioerrore",
                        description: "Errore nel salvataggio",
                        undoButtonText: "Ok",
                    }
                }
            });
        }
    }

    ClickSave() {
        if (this.modalService.hasOpenModals) {
            this.modalService.dismissAll(); /*chiude tutto per sicurezza? */
        }
        const modalRef = this.modalService.open(ModalcontentComponent);
        modalRef.componentInstance.modalPar = {
            name: "salvaBpDef",
            idAzienda: this.riferimentoBpOld.idAziendaRichiedente,
            isRisultatiVolatile: this.isRisultatiVolatile,
            description: this.riferimentoBpOld.riferimentoBp,
            title: "Salvataggio Business Plan",
            actionButtonText: "Salva",
            undoButtonText: "Annulla",
            actionButtonConferma: "Salva definitivo",
        }
        modalRef.result.then(r => {
            if (r.promise) {
                this.nomeSalvataggio = r.nomesalvataggio;
                if (this.nomeSalvataggio != '' && r.conferma != undefined) {
                    this.salvaQuestionario(r.conferma);
                }
            }
        })

    }

    clickOnDettaglio() {
        this.pulsantieraHeader.clickOnDettaglio();
    }

    clickOnDossier() {
        //this.pulsantieraHeader.clickOnDossier();
        //andrà eliminato
        let indiciPagine = [true, true, true, true, true, true, true, true, true, true, true];

        this.pdfService.invioDatiPdf(this.riferimentoBpOld.idAziendaRichiedente, this.riferimentoBpOld.id, indiciPagine).subscribe(
            (data: Blob) => {
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style.visibility = 'hidden';
                let url = URL.createObjectURL(data);
                //a.href =  url+"#toolbar=0";
                a.href = url;
                //a.target = "_blank";
                a.download = this.riferimentoBpOld.riferimentoBp + '.pdf';
                a.click();
                URL.revokeObjectURL(a.href)
                a.remove();
            }
        )
        //////

    }

    clickOnSintesi() {
        this.pulsantieraHeader.clickOnSintesi();
    }

    clickOnExcel() {
        if(this.smartBp){
            this.pulsantieraHeader.generaExcelSmart(this.richiedenteSmart.id, this.dettaglioToSave).subscribe(
                (data: Blob) => {
                    const datePipe = new DatePipe('it');

                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style.visibility = 'hidden';
                    let url = URL.createObjectURL(data);
                    //a.href =  url+"#toolbar=0";
                    a.href = url;
                    a.download = this.richiedenteSmart.ragioneSociale.trim() + datePipe.transform(new Date(), 'yyyy_MM_dd') + '.xlsx';
                    a.click();
                    URL.revokeObjectURL(a.href)
                    a.remove();
    
                }
    
    
            );
        }else{
        this.pulsantieraHeader.generaExcel(this.riferimentoBpOld.idAziendaRichiedente, this.riferimentoBpOld.id).subscribe(
            (data: Blob) => {

                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style.visibility = 'hidden';
                let url = URL.createObjectURL(data);
                //a.href =  url+"#toolbar=0";
                a.href = url;
                a.download = this.riferimentoBpOld.riferimentoBp + '.xlsx';
                a.click();
                URL.revokeObjectURL(a.href)
                a.remove();

            }


        );
    }
    }


    clickOnModifica() {
        this.dettService.cancellaTutto();
        this.splashService.cancellaTutto();
        this.pulsantieraHeader.clickOnSintesi();
        this.router.navigate(["/configurazioneBP"]);
    }

    goToAnagraficaUtente() {
        this.router.navigate(['/censimentocliente']);
    }

    goToSmartBpEvoluto(){
        this.dettService.cancellaTutto();
        this.dettService.aggiornaCommento("");
        this.splashService.cancellaTutto();
        this.pulsantieraHeader.clickOnSintesi();
        this.router.navigate(['/smartBpEvoluto']);
    }

    goToSituazioneFinanziaria(){
        this.dettService.cancellaTutto();
        this.dettService.aggiornaCommento("");
        this.splashService.cancellaTutto();
        this.pulsantieraHeader.clickOnSintesi();
        this.router.navigate(['/situazioneFinanziariaSmart']);
    }

    goBack() {
        let modale: any = null;
        if (this.router.url == "/listaBplanNuova") {
            this.router.navigate(['']);
            //}
        } else if (this.router.url == "/censiementorichiedente") {
            /*  if () {
                 modale = this.openModal();
             modale.result.then((r) => {
                 if (r) {
                     this._location.back();
                 }
             })
             } else { */
            this._location.back();
            //}
        } else if (this.router.url == "/preview") {
            if (this.isRisultatiVolatile) {
                modale = this.openModal();
                modale.result.then((r) => {
                    if (r) {
                        this.router.navigate(["/listaBplanNuova"]);
                    }
                })
            } else {
                this.router.navigate(["/listaBplanNuova"]);
            }
        } else if (this.router.url == "/configurazioneBP") {
            if (this.headerService.isFormDirty() || this.isRisultatiVolatile) {
                modale = this.openModal();
                modale.result.then((r) => {
                    if (r) {
                        this.router.navigate(["/listaBplanNuova"]);
                    }

                })
            } else {
                this.router.navigate(["/listaBplanNuova"]);
            }
        } else if (this.router.url == "/configurazioneambiente") {
            /* if (this.headerService.isFormDirty()) {
                modale = this.openModal();
                modale.result.then((r) => {

                    if (r) {
                        this._location.back();
                    }

                })
            } else { */
            this.router.navigate(['']);
            //}
        } else if (this.router.url == "/gestioneanagrafica") {
            /* if (this.headerService.isFormDirty()) {
                modale = this.openModal();
                modale.result.then((r) => {

                    if (r) {
                        this._location.back();
                    }

                })
            } else { */
            this._location.back();
            // }
        } else {
            this._location.back();
        }


    }
    clickOnGenera() {
        console.log("cliccato su genera")
        this.pulsantieraHeader.caricaConfigurazioneDossier();
    }

    openModal() {
        if (this.modalService.hasOpenModals) {
            this.modalService.dismissAll();
        }
        const modalRef = this.modalService.open(ModalcontentComponent, {
            backdrop: 'static',
            backdropClass: 'customBackdrop',
        });
        modalRef.componentInstance.modalPar = {
            name: "esci",
            title: "Sicuro di voler tornare indietro?",
            description: "Cliccando su 'Indietro' le eventuali modifiche non salvate andranno perse.",
            undoButtonText: "Annulla",
            actionButtonText: "Indietro",
        }

        return modalRef;
    }

    /*     ngOnDestroy(): void {
            this.risultatiVolatileSubscription.unsubscribe();
            this.questionarioBpSubscription.unsubscribe();
            this.bpSuscription.unsubscribe()
            this.navigateSintesiSuscription.unsubscribe();
            this.navigateDossierSuscription.unsubscribe();
            this.navigateDettaglioSuscription.unsubscribe();
         }*/
}
