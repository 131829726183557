<div>
    <!--
    <div class="mat-body-text-2" [ngStyle]="{'color': this.annoInizioBilancio <= 0 ? 'transparent' : ''}">
        <span [hidden]="this.annoInizioBilancio <= 0" style="color: var(--main-black);">INDICI
            AL</span>
        31.12.{{this.annoInizioBilancio}}
       
    </div>
-->
    <div class="row">
        <div class="col-6 flex-justify-center">
        </div>
        <div class="col-6 flex-justify-center" style="font-weight: bold;font-size: 15px;text-align: center;
                                        font-family: var(--UI-font);">
            STORICI
        </div>
    </div>
    <table mat-table [dataSource]=" dataSourceContoEconomico" class="mat-elevation-z8 md-tab">

        <!--- Note that these columns can be defined in any order.
                                The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="INDICI">
            <th mat-header-cell *matHeaderCellDef style="padding-left:2px !important;"> Conto Economico </th>
            <td mat-cell [style.padding-left]="element.indentazione ? '24px' : '12px'" *matCellDef="let element; let i = index"> {{element.indice}} </td>
        </ng-container>

        <ng-container matColumnDef="PRECEDENTE">
            <th mat-header-cell *matHeaderCellDef> {{this.annoInizioBilancio > 0 ? this.annoInizioBilancio - 1 : ''}} </th>
            <td mat-cell *matCellDef="let element; let i = index"> {{this.annoInizioBilancio > 0 ? (element.valueAnnoPrec != 0 ? (element.valueAnnoPrec
                | number:'1.0-0':'it') : '-') : ''}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="VALORE">
            <th mat-header-cell *matHeaderCellDef> {{this.annoInizioBilancio > 0 ? this.annoInizioBilancio : ''}} </th>
            <td mat-cell *matCellDef="let element; let i = index"> {{this.annoInizioBilancio > 0 ? (element.value != 0 ? (element.value
                | number:'1.0-0':'it') : '-') : ''}} </td>
        </ng-container>

        <ng-container matColumnDef="TREND">
            <th mat-header-cell *matHeaderCellDef class="trend"> </th>
            <td mat-cell *matCellDef="let element; let i = index" class="trend"> <i 
                class="font-15 fa {{element.value > element.valueAnnoPrec ? 'fa-caret-up': element.value < element.valueAnnoPrec ? 'fa-caret-down': 'fa-minus font-10 nero'}} {{element.value > element.valueAnnoPrec && (i < 1 || i > 3) || element.value < element.valueAnnoPrec && (i >= 1 && i <= 3) ? 'verde':'rosso'}}"></i>
</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

        <table mat-table [dataSource]="dataSourceCashFlow" class="mat-elevation-z8 md-tab">

            <!--- Note that these columns can be defined in any order.
                                The actual rendered columns are set as a property on the row definition" -->

            <!-- Position Column -->
            <ng-container matColumnDef="INDICI">
                <th mat-header-cell *matHeaderCellDef style="padding-left:2px !important;"> SP Attivo </th>
                <td mat-cell [style.padding-left]="element.indentazione ? '24px' : '12px'" *matCellDef="let element; let i = index"> {{element.indice}} </td>
            </ng-container>

            <ng-container matColumnDef="PRECEDENTE">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element; let i = index"> {{this.annoInizioBilancio > 0 ? (element.valueAnnoPrec != 0 ? (element.valueAnnoPrec
                    | number:'1.0-0':'it') : '-') : ''}} </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="VALORE">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element; let i = index"> {{this.annoInizioBilancio > 0 ? (element.value != 0 ?
                    (element.value | number:'1.0-0':'it') : '-') : '' }}</td>
            </ng-container>

            <ng-container matColumnDef="TREND">
                <th mat-header-cell *matHeaderCellDef class="trend"> </th>
                <td mat-cell *matCellDef="let element; let i = index" class="trend"> <i 
                    class="font-15 fa {{element.value > element.valueAnnoPrec ? 'fa-caret-up': element.value < element.valueAnnoPrec ? 'fa-caret-down': 'fa-minus font-10'}} {{i < 2 ? 'nero' : (element.value > element.valueAnnoPrec && element.value > 0) ? 'verde':'rosso'}}"></i>
    </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
   

    <table mat-table [dataSource]="dataSourceStstoPatrimoniale" class="mat-elevation-z8 md-tab">

        <!--- Note that these columns can be defined in any order.
                                The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="INDICI">
            <th mat-header-cell *matHeaderCellDef style="padding-left:2px !important;">SP Passivo </th>
            <td mat-cell *matCellDef="let element; let i = index" [style.padding-left]="element.indentazione ? '24px' : '12px'" > {{element.indice}} </td>
        </ng-container>

        <ng-container matColumnDef="PRECEDENTE">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element; let i = index"> {{this.annoInizioBilancio > 0 ? (element.valueAnnoPrec > 0 ? (element.valueAnnoPrec
                | number:'1.0-0':'it') : '-') : ''}} </td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="VALORE">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element; let i = index"> {{this.annoInizioBilancio > 0 ? (element.value > 0 ? (element.value
                | number:'1.0-0':'it') : '-') : ''}}</td>
        </ng-container>

        <ng-container matColumnDef="TREND">
            <th mat-header-cell *matHeaderCellDef class="trend"> </th>
            <td mat-cell *matCellDef="let element; let i = index" class="trend"> <i 
                class="font-15 fa {{element.value > element.valueAnnoPrec ? 'fa-caret-up': element.value < element.valueAnnoPrec ? 'fa-caret-down': 'fa-minus font-10'}} {{((element.value > element.valueAnnoPrec && i < 2) || (element.value < element.valueAnnoPrec && i > 2) ||(i == 7 && element.value == 0)) ? 'verde': element.value == element.valueAnnoPrec ? 'nero':'rosso'}}"></i>
</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div class="row">
        
        <div class="col-12 flex-center-left">
            <i>Valori economici in Eur '000</i>
        </div>
    </div>
</div>