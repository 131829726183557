import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { indicAsIs, indicAsIsPicchi } from '../interface/indici-as-is';
import { SplashpageService } from '../services/splashpage.service';
import { TabelleSecondarieService } from '../services/tabelle-secondarie.service';
import { indiciTimeShift } from '../interface/indici-time-shift';
import { indiciSortino, SplashpageServiceService } from 'src/app/_services/splashpage-service.service';
import { DatiQuestionarioService } from 'src/app/_services/dati-questionario.service';
import { DurataBP } from '../interface/durata-bp';
import { ConfigurazioneBusinessPlanService } from 'src/app/_services/configurazione-business-plan.service';
import { ConfigurazioneAmbienteService } from 'src/app/_services/configurazione-ambiente.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-sintesi-end-stroke',
  templateUrl: './sintesi-end-stroke.component.html',
  styleUrls: ['./sintesi-end-stroke.component.css']
})
export class SintesiEndStrokeComponent implements OnInit, OnDestroy {
  @ViewChild('content') content: any;

  dataEndStrokeAppoggio;
  valoreMax: any = {};

  isPersistent: boolean = false;
  idBplan: number;

  displayedColumnsSortino: string[] = ['INDICI', 'VALORE'];
  dataSourcePerditaSortinoTwo;


  displayedColumns: string[] = ['INDICI', 'VALORE', 'ANNO'];

  ELEMENT_AS_IS_PERDITA_SORTINO: indiciSortino[] = [
    //{ label: "Perdita attesa annuale", value: 0 },
    { label: "Indice Sortino", value: 0 }
  ];

  ELEMENT_ML_W_B_first: indiciTimeShift[] = [];

  ELEMENT_ML_W_B_second: indiciTimeShift[] = [];
  ELEMENT_ML_W_B_third: indiciTimeShift[] = [];
  ELEMENT_ML_W_B_fourt: indiciTimeShift[] = [];

  ELEMENT_AS_IS_PERDITA_PICCHI: indicAsIsPicchi[] = [
    { indice: "PICCO DEBITI", value: 0, anno: 0 },
    { indice: "PICCO CREDITI", value: 0, anno: 0 },
    { indice: "PICCO PFN", value: 0, anno: 0 }
  ];


  displayedColumnsCenter: string[] = ['MLCYTD','TR_MLCYTD', 'MLCMT', 'TR_MLCMT', 'MLCLT', 'TR_MLCLT', 'WCYTD', 'TR_WCYTD', 'WCMT', 'TR_WCMT', 'WCLT', 'TR_WCLT',
     'BCYTD', 'TR_BCYTD', 'BCMT', 'TR_BCMT', 'BCLT', 'TR_BCLT'];
displayedColWorst= ['descrizione','ytd','mt','lt']
  dataSourceMWB_first = this.ELEMENT_ML_W_B_first;
  dataSourceMWB_second = this.ELEMENT_ML_W_B_second;
  dataSourceMWB_thirt = this.ELEMENT_ML_W_B_third;
  dataSourceMWB_fourt = this.ELEMENT_ML_W_B_fourt;
  dataSourcePerditaSortino = this.ELEMENT_AS_IS_PERDITA_PICCHI;
  idRuoloUtente = 6;

  constructor(
    private configurazioneAmbienteService: ConfigurazioneAmbienteService, private splashDataVolatile: SplashpageServiceService, private splash_service: SplashpageServiceService, private datiQuestionarioService: DatiQuestionarioService,
    private SplashpageService: SplashpageService, private splashpage_endStroke_volatili: SplashpageServiceService,
    private serviceBp: ConfigurazioneBusinessPlanService, private tabelleSecondarieService: TabelleSecondarieService, private tokenStorageService: TokenStorageService,private modalService: NgbModal) { }
  subscription_valuePicchi: Subscription;
  subscription_responsValueEndStrokeTable: Subscription;
  risultatiVolatileSubscription: Subscription
  bpIdSubscription: Subscription
  endStrokeSubscription: Subscription
  durataBPSubscription: Subscription
  picchiSubscription: Subscription
  sortinoSubscription: Subscription
  matriceWorst: any[] = [];
  durataBP: DurataBP = {
    annoStart: (new Date()).getFullYear(),
    durataAnni: 0
  };

  ngOnDestroy(): void {
    //this.subscription_valuePicchi.unsubscribe();
    //this.subscription_responsValueEndStrokeTable.unsubscribe();
    this.risultatiVolatileSubscription.unsubscribe();
    this.bpIdSubscription.unsubscribe();
    if (!this.isPersistent) {
      this.endStrokeSubscription.unsubscribe()
      this.durataBPSubscription.unsubscribe()

    }
  }

  ngOnInit(): void {
    /*
     this.risultatiVolatileSubscription=   this.datiQuestionarioService.checkFormTouched.subscribe(isVolatile => {
    
          this.isPersistent = !isVolatile;
    
        })*/
   this.idRuoloUtente = this.tokenStorageService.getUser().roles.id;

    this.bpIdSubscription = this.splashpage_endStroke_volatili.BpId$.subscribe(id => {
      this.idBplan = id;
    });

    this.risultatiVolatileSubscription = this.serviceBp.currentRisultatiVolatile.subscribe(isVolatile => {
      this.isPersistent = !isVolatile;
    })

    if (this.isPersistent) {
      if (this.idBplan != 0) {


        this.SplashpageService.postEndStrokeDataTable(this.idBplan).subscribe(
          dataEndStrokeResp => {

            this.dataEndStrokeAppoggio = dataEndStrokeResp;
/*
            this.configurazioneAmbienteService.getValoriMax('risultati').subscribe(
              valMax => {
                valMax.forEach(element => {
                  if (element.error == null) {
                    this.valoreMax[element.nomeCampo] = element.valoreMax;
                  }
                });
                // console.log(this.dataEndStrokeAppoggio)
                if (dataEndStrokeResp.indici_end_stroke[0].mlc > this.valoreMax['dscr']) {
                  dataEndStrokeResp.indici_end_stroke[0].mlcString = '> ' + this.valoreMax['dscr'];
                }
                else if (dataEndStrokeResp.indici_end_stroke[0].mlc < this.valoreMax['dscr'] * (-1)) {
                  dataEndStrokeResp.indici_end_stroke[0].mlcString = '< ' + this.valoreMax['dscr'] * (-1);
                }
                if (dataEndStrokeResp.indici_end_stroke[0].bc > this.valoreMax['dscr']) {
                  dataEndStrokeResp.indici_end_stroke[0].bcString = '> ' + this.valoreMax['dscr'];
                }
                else if (dataEndStrokeResp.indici_end_stroke[0].bc < this.valoreMax['dscr'] * (-1)) {
                  dataEndStrokeResp.indici_end_stroke[0].bcString = '< ' + this.valoreMax['dscr'] * (-1);
                }
                if (dataEndStrokeResp.indici_end_stroke[0].wc > this.valoreMax['dscr']) {
                  dataEndStrokeResp.indici_end_stroke[0].wcString = '> ' + this.valoreMax['dscr'];
                }
                else if (dataEndStrokeResp.indici_end_stroke[0].wc < this.valoreMax['dscr'] * (-1)) {
                  dataEndStrokeResp.indici_end_stroke[0].wcString = '< ' + this.valoreMax['dscr'] * (-1);
                }

                this.dataSourceMWB_first = dataEndStrokeResp.indici_end_stroke;
              }
            );
*/
            this.dataSourceMWB_first = dataEndStrokeResp.indici_end_stroke;
            this.dataSourceMWB_second = dataEndStrokeResp.media_annua_propspetticca_conto_economico;
            this.dataSourceMWB_thirt = dataEndStrokeResp.media_annua_propspetticca_cash_flow;
            dataEndStrokeResp.propspetticca_stato_patrimoniale_end_stroke.splice(1,1);
            this.dataSourceMWB_fourt = dataEndStrokeResp.propspetticca_stato_patrimoniale_end_stroke;
          })

        this.splash_service.postDurataBP(this.idBplan).subscribe(durata => {
          this.durataBP = durata;
          /*
          this.tabelleSecondarieService.getSortinoDataPicchi(this.idBplan).subscribe(valuePicchi => {

            this.ELEMENT_AS_IS_PERDITA_PICCHI = [
              { indice: valuePicchi.piccoDebiti.label, value: valuePicchi.piccoDebiti.value, anno: valuePicchi.piccoDebiti.anno + this.durataBP.annoStart - 1 },
              { indice: valuePicchi.piccoCrediti.label, value: valuePicchi.piccoCrediti.value, anno: valuePicchi.piccoCrediti.anno + this.durataBP.annoStart - 1 },
              { indice: valuePicchi.piccoPFN.label, value: valuePicchi.piccoPFN.value, anno: valuePicchi.piccoPFN.anno + this.durataBP.annoStart - 1 }
            ];

            this.dataSourcePerditaSortino = this.ELEMENT_AS_IS_PERDITA_PICCHI;

          })
            */
        });
/*
        this.tabelleSecondarieService.getSortinoDataTable(this.idBplan).subscribe(
          sortinoValue => {

            let valueSortino_str = JSON.stringify(sortinoValue);
            let valueSortino_json = JSON.parse(valueSortino_str);

            this.ELEMENT_AS_IS_PERDITA_SORTINO = [
              //{ label: valueSortino_json.perditaAttesa.label, value: Number.isFinite(valueSortino_json.perditaAttesa.value) ? valueSortino_json.perditaAttesa.value : null},
              { label: valueSortino_json.IndiceSortino.label, value: valueSortino_json.IndiceSortino.value < 0 ? 0 : valueSortino_json.IndiceSortino.value }
            ];
            this.dataSourcePerditaSortinoTwo = this.ELEMENT_AS_IS_PERDITA_SORTINO;
          })

*/

      }
    } else {



      this.endStrokeSubscription = this.splashpage_endStroke_volatili.endStroke$.subscribe(dataEndStroke => {

        this.dataEndStrokeAppoggio = dataEndStroke

        if (dataEndStroke != null) {
          this.ELEMENT_ML_W_B_first = [];
          this.dataSourceMWB_first
          = this.ELEMENT_ML_W_B_first;
          
          
          this.ELEMENT_ML_W_B_second = this.dataEndStrokeAppoggio.slice(0,7);

          this.ELEMENT_ML_W_B_third = this.dataEndStrokeAppoggio.slice(7,10);
          this.ELEMENT_ML_W_B_fourt = this.dataEndStrokeAppoggio.slice(10,11).concat( this.dataEndStrokeAppoggio.slice(12,19));

          this.matriceWorst = [];
          if(this.dataEndStrokeAppoggio.length > 19) {
            let totaleYtd = this.dataEndStrokeAppoggio[19][0]+ this.dataEndStrokeAppoggio[20][0]+this.dataEndStrokeAppoggio[21][0]+this.dataEndStrokeAppoggio[22][0]+this.dataEndStrokeAppoggio[23][0]+this.dataEndStrokeAppoggio[24][0]+this.dataEndStrokeAppoggio[25][0];
            let totaleMt = this.dataEndStrokeAppoggio[19][1]+ this.dataEndStrokeAppoggio[20][1]+this.dataEndStrokeAppoggio[21][1]+this.dataEndStrokeAppoggio[22][1]+this.dataEndStrokeAppoggio[23][1]+this.dataEndStrokeAppoggio[24][1]+this.dataEndStrokeAppoggio[25][1];
            let totaleLt =this.dataEndStrokeAppoggio[19][2]+ this.dataEndStrokeAppoggio[20][2]+this.dataEndStrokeAppoggio[21][2]+this.dataEndStrokeAppoggio[22][2]+this.dataEndStrokeAppoggio[23][2]+this.dataEndStrokeAppoggio[24][2]+this.dataEndStrokeAppoggio[25][2];

              this.matriceWorst.push({descrizione: 'EVENTO A', ytd: this.dataEndStrokeAppoggio[19][0], mt:this.dataEndStrokeAppoggio[19][1], lt: this.dataEndStrokeAppoggio[19][2]})
              this.matriceWorst.push({descrizione: 'EVENTO B', ytd: this.dataEndStrokeAppoggio[23][0], mt:this.dataEndStrokeAppoggio[23][1], lt: this.dataEndStrokeAppoggio[23][2]})
              this.matriceWorst.push({descrizione: 'EVENTO C', ytd: this.dataEndStrokeAppoggio[24][0], mt:this.dataEndStrokeAppoggio[24][1], lt: this.dataEndStrokeAppoggio[24][2]})
              this.matriceWorst.push({descrizione: 'PRESENZA EVENTI A e B', ytd: this.dataEndStrokeAppoggio[20][0], mt:this.dataEndStrokeAppoggio[20][1], lt: this.dataEndStrokeAppoggio[20][2]})
              this.matriceWorst.push({descrizione: 'PRESENZA EVENTI A e C', ytd: this.dataEndStrokeAppoggio[21][0], mt:this.dataEndStrokeAppoggio[21][1], lt: this.dataEndStrokeAppoggio[21][2]})
              this.matriceWorst.push({descrizione: 'PRESENZA EVENTI B e C', ytd: this.dataEndStrokeAppoggio[25][0], mt:this.dataEndStrokeAppoggio[25][1], lt: this.dataEndStrokeAppoggio[25][2]})
              this.matriceWorst.push({descrizione: 'PRESENZA EVENTI A, B e C', ytd: this.dataEndStrokeAppoggio[22][0], mt:this.dataEndStrokeAppoggio[22][1], lt: this.dataEndStrokeAppoggio[22][2]})
              this.matriceWorst.push({descrizione: 'TOTALE', ytd: totaleYtd, mt:totaleMt, lt: totaleLt})
        
          }
          this.dataSourceMWB_first = this.ELEMENT_ML_W_B_first;
          this.dataSourceMWB_second = this.ELEMENT_ML_W_B_second;
          this.dataSourceMWB_thirt = this.ELEMENT_ML_W_B_third;
          this.dataSourceMWB_fourt = this.ELEMENT_ML_W_B_fourt;

        }
      });

      this.durataBPSubscription = this.splashpage_endStroke_volatili.durataBP$.subscribe(durata => {
        if (durata != null) {
          this.durataBP = durata;

        }
      })
/*
      this.sortinoSubscription = this.splashDataVolatile.sortino$.subscribe(splashPageData => {
        if (splashPageData != null) {
          console.log(splashPageData.IndiceSortino.label);
          this.ELEMENT_AS_IS_PERDITA_SORTINO = [
           // { label: splashPageData.perditaAttesa.label, value: Number.isFinite(splashPageData.perditaAttesa.value) ? splashPageData.perditaAttesa.value : null },
            { label: splashPageData.IndiceSortino.label, value: splashPageData.IndiceSortino.value < 0 ? 0 : splashPageData.IndiceSortino.value }
          ];

          this.dataSourcePerditaSortinoTwo = this.ELEMENT_AS_IS_PERDITA_SORTINO;
        }
      })
*/


    }
  }

  openModal(): void {
    this.modalService.dismissAll();
    this.modalService.open(this.content, { size: 'lg',centered: true });
  }


}
