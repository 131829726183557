import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { UserKey, UserObject, RisultatiBenchDTO, BloccoVariabile, ColonnaBlocchi, ColonnePerVariabile, ValoriBenchDTO, AllDataResults, VariabileBenchmark, BloccoVariabileNew } from '../../interfaces/user-key';
import { MatDialog } from '@angular/material/dialog';
import { CompetitorDialogComponent } from '../competitor-dialog/competitor-dialog.component';
import { PivaDataService } from 'src/app/_services/piva-data.service';
import { tokenToString } from 'typescript';
import { TokenStorageService } from 'src/app/_services/token-storage.service';


@Component({
  selector: 'app-input-benchmark',
  templateUrl: './input-benchmark.component.html',
  styleUrls: ['./input-benchmark.component.css']
})

export class InputBenchmarkComponent implements OnInit {

  idCliente: number = 0; // cliente associato all'utente 2 
  listaRichiedentiUtente: any[] = [] // array di aziende gestite dall'utente 4 prese da utente azienda richiedente 
  listaCompetitors: any[] = []
  risultatiCompetitors: RisultatiBenchDTO[] = []
  risultatoAziendaTarget: RisultatiBenchDTO = {
    descrizione: '', zona: '', valoriAzienda: {
      anno: [], fatturato: [],
      costiProduzioneTot: [], costiMaterie: [], costiServizi: [], costiBeniTerzi: [], costiPersonale: [], ebitda: [], ebit: [],
      roe: [], roi: [], ros: [], rot: [], leva: [], pfnEbitda: [], ratioPatrDebTot: [], ratioPatrDebBanche: [] ,dso: [] ,dpo:[]
    }
  }
  LoggedUser: UserKey = { idUtente: 0 };
  BenchData!: UntypedFormGroup;
  AziendaTarget!: UntypedFormControl;
  TokenTarget: UserObject = { id: 0, descrizione: '', codiceAteco: '' };
  TokenBlocco: BloccoVariabile = { valoreAnno1: 0, valoreAnno2: 0, valoreUltimoAnno: 0, anni: [] }
  TokenColonna: ColonnaBlocchi = { datiAziende: [] }
  TokenTabella: ColonnePerVariabile = { variabile: [] }
  listaVariabili: string[] = [];
  listaVariabiliScelte: VariabileBenchmark[] = [];
  listaAtecoScelti:String[] = [];
  TokenRigaTarget: ColonnaBlocchi = { datiAziende: [] }
  labelAziendaTarget: string = '';
  listaNomiBench: string[] = [];
  nomiVariabili: string[] = [];
  present: boolean = false;
  isBpIn:boolean = false;
  rigaAziendaTarget:BloccoVariabileNew[] = [];
  rigaMediaBench:BloccoVariabileNew[] = []
  rigaDivisoriBench:BloccoVariabileNew[] = [];
  
  tabellaV2:any[] = [];



  constructor(private PivaService: PivaDataService, public dialog: MatDialog, private tokenStorageService: TokenStorageService) {
  }
  
  ngOnInit(): void {

    this.idCliente =this.tokenStorageService.getUser().cliente.idCliente;
    this.LoggedUser = { idUtente: this.tokenStorageService.getUser().idUtente };
    this.PivaService.takeBindedPartIvaByUser(this.LoggedUser).subscribe(
      (data) => {
        this.listaRichiedentiUtente = [];
        data.forEach(el => {
          this.listaRichiedentiUtente.push(el)

        })
      }
    )
    this.BenchData = new UntypedFormGroup({
      AziendaTarget: new UntypedFormControl('')
    })
  }
  setAteco() {
    this.TokenTarget = this.BenchData.controls['AziendaTarget'].value;
    this.controlloBp(this.TokenTarget)
    this.listaCompetitors = [];
    this.risultatiCompetitors = [];
    this.listaAtecoScelti = []
    this.rigaMediaBench = [];
    this.rigaAziendaTarget = [];
    this.tabellaV2 = [];
    this.listaVariabiliScelte = [];
    this.TokenTabella.variabile = [];
    this.TokenRigaTarget.datiAziende = []
    this.present = false;
    this.listaNomiBench = []
    this.labelAziendaTarget = ''
  }


  controlloBp(Target: UserObject){
    this.PivaService.hasBpSaved(Target.id).subscribe((res) =>{
      res == true ? this.isBpIn = true : this.isBpIn = true;
    })
  }
  

  generaTabella() {
    this.listaVariabili = [];
    this.nomiVariabili = [];
    this.tabellaV2 = [];
    this.rigaAziendaTarget = [];

    this.rigaMediaBench = [
      { anno:0,fatturato:' ',ebitda:' ',pfnEbitda:' ',roi:' ',ros:' ',dso:' ',dpo:' ',trendfatturato:' ',trendebitda:' ',trendpfnEbitda:' ',trendroi:' ',trendros:' ',trenddso:' ',trenddpo:' '},
      { anno:0,fatturato:' ',ebitda:' ',pfnEbitda:' ',roi:' ',ros:' ',dso:' ',dpo:' ',trendfatturato:' ',trendebitda:' ',trendpfnEbitda:' ',trendroi:' ',trendros:' ',trenddso:' ',trenddpo:' '},
      { anno:0,fatturato:' ',ebitda:' ',pfnEbitda:' ',roi:' ',ros:' ',dso:' ',dpo:' ',trendfatturato:' ',trendebitda:' ',trendpfnEbitda:' ',trendroi:' ',trendros:' ',trenddso:' ',trenddpo:' '},
      { anno:0,fatturato:' ',ebitda:' ',pfnEbitda:' ',roi:' ',ros:' ',dso:' ',dpo:' ',trendfatturato:' ',trendebitda:' ',trendpfnEbitda:' ',trendroi:' ',trendros:' ',trenddso:' ',trenddpo:' '},
      { anno:0,fatturato:' ',ebitda:' ',pfnEbitda:' ',roi:' ',ros:' ',dso:' ',dpo:' ',trendfatturato:' ',trendebitda:' ',trendpfnEbitda:' ',trendroi:' ',trendros:' ',trenddso:' ',trenddpo:' '} ]
   
      this.rigaDivisoriBench = [
        { anno:0,fatturato:this.listaCompetitors.length-1,ebitda:this.listaCompetitors.length-1,pfnEbitda:this.listaCompetitors.length-1,roi:this.listaCompetitors.length-1,ros:this.listaCompetitors.length-1,dso:this.listaCompetitors.length-1,dpo:this.listaCompetitors.length-1,trendfatturato:this.listaCompetitors.length-1,trendebitda:this.listaCompetitors.length-1,trendpfnEbitda:this.listaCompetitors.length-1,trendroi:this.listaCompetitors.length-1,trendros:this.listaCompetitors.length-1,trenddso:this.listaCompetitors.length-1,trenddpo:this.listaCompetitors.length-1},
        { anno:0,fatturato:this.listaCompetitors.length-1,ebitda:this.listaCompetitors.length-1,pfnEbitda:this.listaCompetitors.length-1,roi:this.listaCompetitors.length-1,ros:this.listaCompetitors.length-1,dso:this.listaCompetitors.length-1,dpo:this.listaCompetitors.length-1,trendfatturato:this.listaCompetitors.length-1,trendebitda:this.listaCompetitors.length-1,trendpfnEbitda:this.listaCompetitors.length-1,trendroi:this.listaCompetitors.length-1,trendros:this.listaCompetitors.length-1,trenddso:this.listaCompetitors.length-1,trenddpo:this.listaCompetitors.length-1},
        { anno:0,fatturato:this.listaCompetitors.length-1,ebitda:this.listaCompetitors.length-1,pfnEbitda:this.listaCompetitors.length-1,roi:this.listaCompetitors.length-1,ros:this.listaCompetitors.length-1,dso:this.listaCompetitors.length-1,dpo:this.listaCompetitors.length-1,trendfatturato:this.listaCompetitors.length-1,trendebitda:this.listaCompetitors.length-1,trendpfnEbitda:this.listaCompetitors.length-1,trendroi:this.listaCompetitors.length-1,trendros:this.listaCompetitors.length-1,trenddso:this.listaCompetitors.length-1,trenddpo:this.listaCompetitors.length-1},
        { anno:0,fatturato:this.listaCompetitors.length-1,ebitda:this.listaCompetitors.length-1,pfnEbitda:this.listaCompetitors.length-1,roi:this.listaCompetitors.length-1,ros:this.listaCompetitors.length-1,dso:this.listaCompetitors.length-1,dpo:this.listaCompetitors.length-1,trendfatturato:this.listaCompetitors.length-1,trendebitda:this.listaCompetitors.length-1,trendpfnEbitda:this.listaCompetitors.length-1,trendroi:this.listaCompetitors.length-1,trendros:this.listaCompetitors.length-1,trenddso:this.listaCompetitors.length-1,trenddpo:this.listaCompetitors.length-1},
        { anno:0,fatturato:this.listaCompetitors.length-1,ebitda:this.listaCompetitors.length-1,pfnEbitda:this.listaCompetitors.length-1,roi:this.listaCompetitors.length-1,ros:this.listaCompetitors.length-1,dso:this.listaCompetitors.length-1,dpo:this.listaCompetitors.length-1,trendfatturato:this.listaCompetitors.length-1,trendebitda:this.listaCompetitors.length-1,trendpfnEbitda:this.listaCompetitors.length-1,trendroi:this.listaCompetitors.length-1,trendros:this.listaCompetitors.length-1,trenddso:this.listaCompetitors.length-1,trenddpo:this.listaCompetitors.length-1} ]

 
    this.listaVariabiliScelte.forEach(el => {
      this.nomiVariabili.push(el.variabileLabel)
      this.listaVariabili.push(el.variabile)
    });


  

    this.risultatoAziendaTarget.valoriAzienda.anno.reverse().forEach((RisAnno,index) => 
      {
        let BloccoTemporaneo: BloccoVariabileNew = { anno:0,
          fatturato:' ',
          ebitda:' ',
          pfnEbitda:' ',
          roi:' ',
          ros:' ',
          dso:' ',
          dpo:' ',
          trendfatturato:' ',
          trendebitda:' ',
          trendpfnEbitda:' ',
          trendroi:' ',
          trendros:' ',
          trenddso:' ',
          trenddpo:' '
          };

        BloccoTemporaneo['anno'] = RisAnno;
        this.listaVariabili.forEach(VarAnno => {
          
          this.risultatoAziendaTarget.valoriAzienda[VarAnno][index] != null ? 
          BloccoTemporaneo[VarAnno] =  this.risultatoAziendaTarget.valoriAzienda[VarAnno][index] : 'N.d.';


          this.risultatoAziendaTarget.valoriAzienda[VarAnno][index] != null &&  this.risultatoAziendaTarget.valoriAzienda[VarAnno][index+1] != null &&  this.risultatoAziendaTarget.valoriAzienda[VarAnno][index+1]  != 0  ? 
          BloccoTemporaneo['trend'+VarAnno] = ((this.risultatoAziendaTarget.valoriAzienda[VarAnno][index] - this.risultatoAziendaTarget.valoriAzienda[VarAnno][index+1]) / Math.abs(this.risultatoAziendaTarget.valoriAzienda[VarAnno][index+1]))*100 : 'N.d.';

        })
      
        this.rigaAziendaTarget.push(BloccoTemporaneo)
    })
  

    this.risultatiCompetitors.forEach(Risultato => {
      let rigaAzienda:BloccoVariabileNew[] = [];
      
      Risultato.valoriAzienda.anno.reverse().forEach((RisAnno,index) => {
        let BloccoTemporaneo: BloccoVariabileNew = { anno:0,
          fatturato:' ',
          ebitda:' ',
          pfnEbitda:' ',
          roi:' ',
          ros:' ',
          dso:' ',
          dpo:' ',
          trendfatturato:' ',
          trendebitda:' ',
          trendpfnEbitda:' ',
          trendroi:' ',
          trendros:' ',
          trenddso:' ',
          trenddpo:' '
          };

    
        BloccoTemporaneo['anno'] = RisAnno;
        this.rigaMediaBench[index]['anno'] = RisAnno;
        this.listaVariabili.forEach( (VarAnno)  => {
          
          Risultato.valoriAzienda[VarAnno][index] != null ? 
          BloccoTemporaneo[VarAnno] =Risultato.valoriAzienda[VarAnno][index] : 'N.d.';

          Risultato.valoriAzienda[VarAnno][index] != null ? this.rigaMediaBench[index][VarAnno] = +this.rigaMediaBench[index][VarAnno]  + Risultato.valoriAzienda[VarAnno][index] :  this.rigaDivisoriBench[index][VarAnno] = +this.rigaDivisoriBench[index][VarAnno] -1 ;
          
          Risultato.valoriAzienda[VarAnno][index] != null &&  Risultato.valoriAzienda[VarAnno][index+1] != null &&  Risultato.valoriAzienda[VarAnno][index+1]  != 0  ? 
          BloccoTemporaneo['trend'+VarAnno] = ((Risultato.valoriAzienda[VarAnno][index] - Risultato.valoriAzienda[VarAnno][index+1]) / Math.abs(Risultato.valoriAzienda[VarAnno][index+1]))*100 : 'N.d.';

        })

        rigaAzienda.push(BloccoTemporaneo);
        
      })

      this.tabellaV2.push(rigaAzienda)
     
    })
    this.listaVariabili.forEach(Variabile =>{
      for(let i = 0; i < 5 ; i++){
       this.rigaMediaBench[i][Variabile] != null && this.rigaMediaBench[i][Variabile] != ' ' && this.rigaMediaBench[i][Variabile] != 'N.d.' && this.rigaDivisoriBench[i][Variabile] != 0   ?  this.rigaMediaBench[i][Variabile] = +this.rigaMediaBench[i][Variabile]/+this.rigaDivisoriBench[i][Variabile] : 'N.d.' ; 
      
      }
      for(let i = 0; i < 4 ; i++){
      
        this.rigaMediaBench[i][Variabile] != ' '  && this.rigaMediaBench[i+1][Variabile] != ' '  && this.rigaMediaBench[i+1][Variabile] != 0 ? this.rigaMediaBench[i]['trend'+Variabile] = ((+this.rigaMediaBench[i][Variabile] - +this.rigaMediaBench[i+1][Variabile])/Math.abs(+this.rigaMediaBench[i+1][Variabile]))*100 : 'N.d.'
      }
    })
    this.present = true;
   
  }

  openDialog(): void {
 this.present = false;
    const dialogRef = this.dialog.open(CompetitorDialogComponent, {
      width: '90vw',
      height: '90vh',
      data: { tk: this.TokenTarget, listSelected: this.listaCompetitors, varSelected: this.listaVariabiliScelte , atecoSelected:this.listaAtecoScelti  }

    });

    dialogRef.afterClosed().subscribe((result)  => {
      if (result) {
        if (result.data.compets.length >= 0 ) {
          this.listaVariabiliScelte = (result.data.vars as VariabileBenchmark[]);
          this.listaVariabiliScelte.sort((n1, n2) => n1.id - n2.id)
          this.listaCompetitors = [];
          this.risultatiCompetitors = [];


          this.listaCompetitors.push(this.TokenTarget);
          result.data.compets.forEach((el: UserObject) => {
            this.listaCompetitors.push(el)
          })

          if(result.data.atecos.length > 0){
            this.listaAtecoScelti = []
            this.listaAtecoScelti = result.data.atecos;
          }

          if (this.listaCompetitors.length != 0 && result.data.isCancel == false) {
            this.listaNomiBench = [];
            this.PivaService.getUserObjectData(this.listaCompetitors).subscribe(
              (data: AllDataResults) => {

                this.labelAziendaTarget = (data.aziendaMia.descrizione);

                data.competitors.forEach(el => {
                  this.risultatiCompetitors.push(el)
                  this.listaNomiBench.push(el.descrizione)
                })
                this.risultatoAziendaTarget = data.aziendaMia;

                this.generaTabella();
              }
            )
          }
        }

     



      }
    });


  }

  evidenziaAzienda(az: string) {
    let elems = Array.from(window.document.getElementsByClassName(az) as HTMLCollectionOf<HTMLElement>)
    let allelem = Array.from(window.document.getElementsByClassName('container_bench') as HTMLCollectionOf<HTMLElement>)
    for (var i = 0; i < allelem.length; i++) {
      allelem[i].style.boxShadow = ' 5px 5px 8px 0px black';
    }
    for (var i = 0; i < window.document.getElementsByClassName(az).length; i++) {
      let dib = Array.from(elems[i].getElementsByClassName('container_bench') as HTMLCollectionOf<HTMLElement>)
      for (var j = 0; j < dib.length; j++) {
        dib[j].style.boxShadow = ' 7px 7px 10px 0px #0B5ED7';
      }
    }
  }




}
