<div id="conatainer_endStroke">
    <div class="row md-tab-center">
        <div class="col-4 flex-justify-center"style="font-weight: bold;font-size: 15px;text-align: center;
                                        font-family: var(--UI-font);">
            EXPECTED CASES &nbsp; <span style="font-weight: normal">{{dataSourceMWB_second && dataSourceMWB_second[0] && dataSourceMWB_second[0].prob_mlc ? ' (' + (dataSourceMWB_second[0].prob_mlc*100. |  number:'1.0-1':'it') + '%)': ''}}</span>
        </div>
        <div class="col-4 flex-justify-center" style="font-weight: bold;font-size: 15px;text-align: center;
                                        font-family: var(--UI-font);">
            WORST CASES &nbsp; <span style="font-weight: normal">{{dataSourceMWB_second && dataSourceMWB_second[0] && dataSourceMWB_second[0].prob_wc ? ' (' + (dataSourceMWB_second[0].prob_wc*100. |  number:'1.0-1':'it') + '%)': ''}}</span>
        <span *ngIf="dataSourceMWB_second && dataSourceMWB_second[0] && dataSourceMWB_second[0].prob_wc > 0"> <mat-icon style="font-size: 15px; align-content: center; cursor:pointer" class="material-icons-outlined action-icon" (click)="openModal()">search</mat-icon></span>
        </div>
        <div class="col-4 flex-justify-center"style="font-weight: bold;font-size: 15px;text-align: center;
                                        font-family: var(--UI-font);">
            BEST CASES &nbsp;  <span style="font-weight: normal">{{dataSourceMWB_second && dataSourceMWB_second[0] && dataSourceMWB_second[0].prob_bc ? ' (' + (dataSourceMWB_second[0].prob_bc*100. |  number:'1.0-1':'it') + '%)': ''}}</span>
        </div>
    </div>
    <table mat-table [dataSource]="dataSourceMWB_second" class="mat-elevation-z8 md-tab-center">

        <!--- Note that these columns can be defined in any order.
                                                The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="MLCYTD">
            <th mat-header-cell *matHeaderCellDef class="mlc" styLe="text-align: end !important;">{{this.durataBP.annoStart}} </th>
            <td mat-cell *matCellDef="let element; let i = index"> {{element.mlc != 0 ? (element.mlc | number:'1.0-0':'it') :
                '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="TR_MLCYTD">
            <th mat-header-cell *matHeaderCellDef class="mlc trend"> </th>
            <td mat-cell *matCellDef="let element; let i = index" class="trend"> <i 
                class="font-15 fa {{element.mlc > element.annoPrec ? 'fa-caret-up': element.mlc < element.annoPrec ? 'fa-caret-down': 'fa-minus font-10 nero'}} {{element.mlc > element.annoPrec && (i <1 || i > 3) || element.mlc < element.annoPrec  && (i >= 1 && i <= 3) ? 'verde':'rosso'}}"></i> </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="MLCMT">
            <th mat-header-cell *matHeaderCellDef class="mlc" styLe="text-align: end !important;"> MT </th>
            <td mat-cell *matCellDef="let element; let i = index"> {{element.mlcMedioTerm != 0 ? (element.mlcMedioTerm | number:'1.0-0':'it') :
                '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="TR_MLCMT">
            <th mat-header-cell *matHeaderCellDef class="mlc trend"> </th>
            <td mat-cell *matCellDef="let element; let i = index" class="trend"> <i 
                class="font-15 fa {{element.mlcMedioTerm > element.mlc ? 'fa-caret-up': element.mlcMedioTerm < element.mlc ? 'fa-caret-down': 'fa-minus font-10 nero'}} {{element.mlcMedioTerm > element.mlc && (i <1 || i > 3) || element.mlcMedioTerm < element.mlc  && (i >= 1 && i <= 3) ? 'verde':'rosso'}}"></i> </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="MLCLT">
            <th mat-header-cell *matHeaderCellDef class="mlc" styLe="text-align: end !important;">LT</th>
            <td mat-cell *matCellDef="let element; let i = index"> {{element.mlcLungoTerm != 0 ? (element.mlcLungoTerm | number:'1.0-0':'it') :
                '-'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="TR_MLCLT">
            <th mat-header-cell *matHeaderCellDef class="mlc trend" style="border-right: 1px solid #cccccc;"> </th>
            <td mat-cell *matCellDef="let element; let i = index" class="trend" style="border-right: 1px solid #cccccc;"> <i 
                class="font-15 fa {{element.mlcLungoTerm > element.mlcMedioTerm ? 'fa-caret-up': element.mlcLungoTerm < element.mlcMedioTerm ? 'fa-caret-down': 'fa-minus font-10 nero'}} {{element.mlcLungoTerm > element.mlcMedioTerm && (i <1 || i > 3) || element.mlcLungoTerm < element.mlcMedioTerm  && (i >= 1 && i <= 3) ? 'verde':'rosso'}}"></i> </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="WCYTD">
            <th mat-header-cell *matHeaderCellDef class="wc" styLe="text-align: end !important;">{{this.durataBP.annoStart}}  </th>
            <td mat-cell *matCellDef="let element; let i = index"> {{element.wc != 0 ? (element.wc | number:'1.0-0':'it') :
                '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="TR_WCYTD">
            <th mat-header-cell *matHeaderCellDef class="wc trend"> </th>
            <td mat-cell *matCellDef="let element; let i = index" class="trend"> <i 
                class="font-15 fa {{element.wc > element.annoPrec ? 'fa-caret-up': element.wc < element.annoPrec ? 'fa-caret-down': 'fa-minus font-10 nero'}} {{element.wc > element.annoPrec && (i <1 || i > 3) || element.wc < element.annoPrec  && (i >= 1 && i <= 3) ? 'verde':'rosso'}}" ></i> </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="WCMT">
            <th mat-header-cell *matHeaderCellDef class="wc" styLe="text-align: end !important;"> MT </th>
            <td mat-cell *matCellDef="let element; let i = index"> {{element.wcMedioTerm != 0 ? (element.wcMedioTerm | number:'1.0-0':'it') :
                '-'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="TR_WCMT">
            <th mat-header-cell *matHeaderCellDef class="wc trend"> </th>
            <td mat-cell *matCellDef="let element; let i = index" class="trend"> <i 
                class="font-15 fa {{element.wcMedioTerm > element.wc? 'fa-caret-up': element.wcMedioTerm < element.wc ? 'fa-caret-down': 'fa-minus font-10 nero'}} {{element.wcMedioTerm > element.wc && (i <1 || i > 3) || element.wcMedioTerm < element.wc  && (i >= 1 && i <= 3) ? 'verde':'rosso'}}" ></i> </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="WCLT">
            <th mat-header-cell *matHeaderCellDef class="wc" styLe="text-align: end !important;">LT </th>
            <td mat-cell *matCellDef="let element; let i = index" > {{element.wcLungoTerm != 0 ? (element.wcLungoTerm | number:'1.0-0':'it') :
                '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="TR_WCLT">
            <th mat-header-cell *matHeaderCellDef class="wc trend" style="border-right: 1px solid #cccccc;"> </th>
            <td mat-cell *matCellDef="let element; let i = index" class="trend" style="border-right: 1px solid #cccccc;"> <i 
                class="font-15 fa {{element.wcLungoTerm > element.wcMedioTerm? 'fa-caret-up': element.wcLungoTerm < element.wcMedioTerm ? 'fa-caret-down': 'fa-minus font-10 nero'}} {{element.wcLungoTerm > element.wcMedioTerm && (i <1 || i > 3) || element.wcLungoTerm < element.wcMedioTerm  && (i >= 1 && i <= 3) ? 'verde':'rosso'}}"></i> </td>
        </ng-container>

        <ng-container matColumnDef="BCYTD">
            <th mat-header-cell *matHeaderCellDef class="bc" styLe="text-align: end !important;"> {{this.durataBP.annoStart}} </th>
            <td mat-cell *matCellDef="let element; let i = index"> {{element.bc != 0 ? (element.bc | number:'1.0-0':'it') :
                '-'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="TR_BCYTD">
        <th mat-header-cell *matHeaderCellDef class="bc trend"> </th>
        <td mat-cell *matCellDef="let element; let i = index" class="trend"> <i 
            class="font-15 fa {{element.bc > element.annoPrec ? 'fa-caret-up': element.bc < element.annoPrec ? 'fa-caret-down': 'fa-minus font-10 nero'}} {{element.bc > element.annoPrec && (i <1 || i > 3) || element.bc < element.annoPrec  && (i >= 1 && i <= 3) ? 'verde':'rosso'}}"></i> </td>
    </ng-container>

        <ng-container matColumnDef="BCMT">
            <th mat-header-cell *matHeaderCellDef class="bc" styLe="text-align: end !important;"> MT </th>
            <td mat-cell *matCellDef="let element; let i = index"> {{element.bcMedioTerm != 0 ? (element.bcMedioTerm | number:'1.0-0':'it') :
                '-'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="TR_BCMT">
            <th mat-header-cell *matHeaderCellDef class="bc trend" > </th>
            <td mat-cell *matCellDef="let element; let i = index" class="trend"> <i 
                class="font-15 fa {{element.bcMedioTerm > element.bc? 'fa-caret-up': element.bcMedioTerm < element.bc ? 'fa-caret-down': 'fa-minus font-10 nero'}} {{element.bcMedioTerm > element.bc && (i <1 || i > 3) || element.bcMedioTerm < element.bc  && (i >= 1 && i <= 3) ? 'verde':'rosso'}}"></i> </td>
        </ng-container>

        <ng-container matColumnDef="BCLT">
            <th mat-header-cell *matHeaderCellDef class="bc" styLe="text-align: end !important;">LT </th>
            <td mat-cell *matCellDef="let element; let i = index"> {{element.bcLungoTerm != 0 ? (element.bcLungoTerm | number:'1.0-0':'it') :
                '-'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="TR_BCLT">
            <th mat-header-cell *matHeaderCellDef class="bc trend"> </th>
            <td mat-cell *matCellDef="let element; let i = index" class="trend"> <i 
                class="font-15 fa {{element.bcLungoTerm > element.bcMedioTerm? 'fa-caret-up': element.bcLungoTerm < element.bcMedioTerm ? 'fa-caret-down': 'fa-minus font-10 nero'}} {{element.bcLungoTerm > element.bcMedioTerm && (i <1 || i > 3) || element.bcLungoTerm < element.bcMedioTerm  && (i >= 1 && i <= 3) ? 'verde':'rosso'}}"></i> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef=" displayedColumnsCenter"></tr>
        <tr mat-row *matRowDef="let row; columns:  displayedColumnsCenter;"></tr>
    </table>
    <table mat-table [dataSource]="dataSourceMWB_thirt" class="mat-elevation-z8 md-tab-center">

        <!--- Note that these columns can be defined in any order.
                                                The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="MLCYTD">
            <th mat-header-cell *matHeaderCellDef class="mlc" style="color: transparent !important">.</th>
            <td mat-cell *matCellDef="let element; let i = index"> {{element.mlc != 0 ? (element.mlc | number:'1.0-0':'it') :
                '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="TR_MLCYTD">
            <th mat-header-cell *matHeaderCellDef class="mlc trend"> </th>
            <td mat-cell *matCellDef="let element; let i = index" class="trend"> <i 
                class="font-15 fa {{element.mlc > element.annoPrec ? 'fa-caret-up': element.mlc < element.annoPrec ? 'fa-caret-down': 'fa-minus font-10'}} {{i < 2 ? 'nero' : (element.mlc > element.annoPrec && element.mlc > 0) ? 'verde':'rosso'}}"></i> </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="MLCMT">
            <th mat-header-cell *matHeaderCellDef class="mlc"> </th>
            <td mat-cell *matCellDef="let element; let i = index"> {{element.mlcMedioTerm != 0 ? (element.mlcMedioTerm | number:'1.0-0':'it') :
                '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="TR_MLCMT">
            <th mat-header-cell *matHeaderCellDef class="mlc trend"> </th>
            <td mat-cell *matCellDef="let element; let i = index" class="trend"> <i 
                class="font-15 fa {{element.mlcMedioTerm > element.mlc ? 'fa-caret-up': element.mlcMedioTerm < element.mlc ? 'fa-caret-down': 'fa-minus font-10'}} {{i < 2 ? 'nero' : (element.mlcMedioTerm > element.mlc && element.mlcMedioTerm > 0) ? 'verde':'rosso'}}"></i> </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="MLCLT">
            <th mat-header-cell *matHeaderCellDef class="mlc"> </th>
            <td mat-cell *matCellDef="let element; let i = index"> {{element.mlcLungoTerm != 0 ? (element.mlcLungoTerm | number:'1.0-0':'it') :
                '-'}}
            </td>
        </ng-container>
        <ng-container matColumnDef="TR_MLCLT">
            <th mat-header-cell *matHeaderCellDef class="mlc trend" style="border-right: 1px solid #cccccc;"> </th>
            <td mat-cell *matCellDef="let element; let i = index" class="trend" style="border-right: 1px solid #cccccc;"> <i 
                class="font-15 fa {{element.mlcLungoTerm > element.mlcMedioTerm ? 'fa-caret-up': element.mlcLungoTerm < element.mlcMedioTerm ? 'fa-caret-down': 'fa-minus font-10'}} {{i < 2 ? 'nero' : (element.mlcLungoTerm > element.mlcMedioTerm && element.mlcLungoTerm > 0) ? 'verde':'rosso'}}"></i> </td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="WCYTD">
            <th mat-header-cell *matHeaderCellDef class="wc">  </th>
            <td mat-cell *matCellDef="let element; let i = index"> {{element.wc != 0 ? (element.wc | number:'1.0-0':'it') :
                '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="TR_WCYTD">
            <th mat-header-cell *matHeaderCellDef class="wc trend"> </th>
            <td mat-cell *matCellDef="let element; let i = index" class="trend"> <i 
                class="font-15 fa {{element.wc > element.annoPrec ? 'fa-caret-up': element.wc < element.annoPrec ? 'fa-caret-down': 'fa-minus font-10'}} {{i < 2 ? 'nero' : (element.wc > element.annoPrec && element.wc > 0) ? 'verde':'rosso'}}"></i> </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="WCMT">
            <th mat-header-cell *matHeaderCellDef class="wc"> </th>
            <td mat-cell *matCellDef="let element; let i = index"> {{element.wcMedioTerm != 0 ? (element.wcMedioTerm | number:'1.0-0':'it') :
                '-'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="TR_WCMT">
            <th mat-header-cell *matHeaderCellDef class="wc trend"> </th>
            <td mat-cell *matCellDef="let element; let i = index" class="trend"> <i 
                class="font-15 fa {{element.wcMedioTerm > element.wc? 'fa-caret-up': element.wcMedioTerm < element.wc ? 'fa-caret-down': 'fa-minus font-10'}} {{i < 2 ? 'nero' : (element.wcMedioTerm > element.wc && element.wcMedioTerm > 0) ? 'verde':'rosso'}}"></i> </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="WCLT">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element; let i = index"> {{element.wcLungoTerm != 0 ? (element.wcLungoTerm | number:'1.0-0':'it') :
                '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="TR_WCLT">
            <th mat-header-cell *matHeaderCellDef class="wc trend" style="border-right: 1px solid #cccccc;"> </th>
            <td mat-cell *matCellDef="let element; let i = index" class="trend" style="border-right: 1px solid #cccccc;"> <i 
                class="font-15 fa {{element.wcLungoTerm > element.wcMedioTerm? 'fa-caret-up': element.wcLungoTerm < element.wcMedioTerm ? 'fa-caret-down': 'fa-minus font-10'}} {{i < 2 ? 'nero' : (element.wcLungoTerm > element.wcMedioTerm && element.wcLungoTerm > 0) ? 'verde':'rosso'}}"></i> </td>
        </ng-container>

        <ng-container matColumnDef="BCYTD">
            <th mat-header-cell *matHeaderCellDef class="bc">  </th>
            <td mat-cell *matCellDef="let element; let i = index"> {{element.bc != 0 ? (element.bc | number:'1.0-0':'it') :
                '-'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="TR_BCYTD">
            <th mat-header-cell *matHeaderCellDef class="bc trend"> </th>
            <td mat-cell *matCellDef="let element; let i = index" class="trend"> <i 
                class="font-15 fa {{element.bc > element.annoPrec ? 'fa-caret-up': element.bc < element.annoPrec ? 'fa-caret-down': 'fa-minus font-10'}} {{i < 2 ? 'nero' : (element.mlc > element.annoPrec && element.mlc > 0) ? 'verde':'rosso'}}"></i> </td>
        </ng-container>
    
        <ng-container matColumnDef="BCMT">
            <th mat-header-cell *matHeaderCellDef class="bc"> </th>
            <td mat-cell *matCellDef="let element; let i = index"> {{element.bcMedioTerm != 0 ? (element.bcMedioTerm | number:'1.0-0':'it') :
                '-'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="TR_BCMT">
            <th mat-header-cell *matHeaderCellDef class="bc trend"> </th>
            <td mat-cell *matCellDef="let element; let i = index" class="trend"> <i 
                class="font-15 fa {{element.bcMedioTerm > element.bc? 'fa-caret-up': element.bcMedioTerm < element.bc ? 'fa-caret-down': 'fa-minus font-10'}} {{i < 2 ? 'nero' : (element.bcMedioTerm > element.bc && element.bcMedioTerm > 0) ? 'verde':'rosso'}}"></i> </td>
        </ng-container>

        <ng-container matColumnDef="BCLT">
            <th mat-header-cell *matHeaderCellDef class="bc"> </th>
            <td mat-cell *matCellDef="let element; let i = index"> {{element.bcLungoTerm != 0 ? (element.bcLungoTerm | number:'1.0-0':'it') :
                '-'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="TR_BCLT">
            <th mat-header-cell *matHeaderCellDef class="bc trend"> </th>
            <td mat-cell *matCellDef="let element; let i = index" class="trend"> <i 
                class="font-15 fa {{element.bcLungoTerm > element.bcMedioTerm? 'fa-caret-up': element.bcLungoTerm < element.bcMedioTerm ? 'fa-caret-down': 'fa-minus font-10'}} {{i < 2 ? 'nero' : (element.bcLungoTerm > element.bcMedioTerm && element.bcLungoTerm > 0) ? 'verde':'rosso'}}"></i> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef=" displayedColumnsCenter"></tr>
        <tr mat-row *matRowDef="let row; columns:  displayedColumnsCenter;"></tr>
    </table>
    <table mat-table [dataSource]="dataSourceMWB_fourt" class="mat-elevation-z8 md-tab-center ">

        <!--- Note that these columns can be defined in any order.
                                                The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="MLCYTD">
            <th mat-header-cell *matHeaderCellDef class="mlc"style="color: transparent !important">. </th>
            <td mat-cell *matCellDef="let element; let i = index"> {{element.mlc != 0 ? (element.mlc | number:'1.0-0':'it') :
                '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="TR_MLCYTD">
            <th mat-header-cell *matHeaderCellDef class="mlc trend"> </th>
            <td mat-cell *matCellDef="let element; let i = index" class="trend"> <i 
                class="font-15 fa {{element.mlc > element.annoPrec ? 'fa-caret-up': element.mlc < element.annoPrec ? 'fa-caret-down': 'fa-minus font-10'}} {{((element.mlc > element.annoPrec && i < 2) || (element.mlc < element.annoPrec && i > 2) ||(i == 7 && element.mlc == 0)) ? 'verde': element.mlc == element.annoPrec ? 'nero':'rosso'}}"></i> </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="MLCMT">
            <th mat-header-cell *matHeaderCellDef class="mlc"> </th>
            <td mat-cell *matCellDef="let element; let i = index"> {{element.mlcMedioTerm != 0 ? (element.mlcMedioTerm | number:'1.0-0':'it') :
                '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="TR_MLCMT">
            <th mat-header-cell *matHeaderCellDef class="mlc trend"> </th>
            <td mat-cell *matCellDef="let element; let i = index" class="trend"> <i 
                class="font-15 fa {{element.mlcMedioTerm > element.mlc ? 'fa-caret-up': element.mlcMedioTerm < element.mlc ? 'fa-caret-down': 'fa-minus font-10'}} {{((element.mlcMedioTerm > element.mlc && i < 2) || (element.mlcMedioTerm < element.mlc && i > 2) || (i == 7 && element.mlcMedioTerm == 0)) ? 'verde': element.mlcMedioTerm == element.mlc ? 'nero':'rosso'}}"></i> </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="MLCLT">
            <th mat-header-cell *matHeaderCellDef class="mlc"> </th>
            <td mat-cell *matCellDef="let element; let i = index"> {{element.mlcLungoTerm != 0 ? (element.mlcLungoTerm | number:'1.0-0':'it') :
                '-'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="TR_MLCLT">
            <th mat-header-cell *matHeaderCellDef class="mlc trend" style="border-right: 1px solid #cccccc;"> </th>
            <td mat-cell *matCellDef="let element; let i = index" class="trend" style="border-right: 1px solid #cccccc;"> <i 
                class="font-15 fa {{element.mlcLungoTerm > element.mlcMedioTerm ? 'fa-caret-up': element.mlcLungoTerm < element.mlcMedioTerm ? 'fa-caret-down': 'fa-minus font-10'}} {{((element.mlcLungoTerm > element.mlcMedioTerm && i < 2) || (element.mlcLungoTerm < element.mlcMedioTerm && i > 2) || (i == 7 && element.mlcLungoTerm == 0)) ? 'verde': element.mlcLungoTerm == element.mlcMedioTerm ? 'nero':'rosso'}}"></i> </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="WCYTD">
            <th mat-header-cell *matHeaderCellDef class="wc">  </th>
            <td mat-cell *matCellDef="let element; let i = index"> {{element.wc != 0 ? (element.wc | number:'1.0-0':'it') :
                '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="TR_WCYTD">
            <th mat-header-cell *matHeaderCellDef class="wc trend"> </th>
            <td mat-cell *matCellDef="let element; let i = index" class="trend"> <i 
                class="font-15 fa {{element.wc > element.annoPrec ? 'fa-caret-up': element.wc < element.annoPrec ? 'fa-caret-down': 'fa-minus font-10'}} {{((element.wc > element.annoPrec && i < 2) || (element.wc < element.annoPrec && i > 2) ||(i == 7 && element.wc == 0)) ? 'verde': element.wc == element.annoPrec ? 'nero':'rosso'}}"></i> </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="WCMT">
            <th mat-header-cell *matHeaderCellDef class="wc"> </th>
            <td mat-cell *matCellDef="let element; let i = index"> {{element.wcMedioTerm != 0 ? (element.wcMedioTerm | number:'1.0-0':'it') :
                '-'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="TR_WCMT">
            <th mat-header-cell *matHeaderCellDef class="wc trend"> </th>
            <td mat-cell *matCellDef="let element; let i = index" class="trend"> <i 
                class="font-15 fa {{element.wcMedioTerm > element.wc? 'fa-caret-up': element.wcMedioTerm < element.wc ? 'fa-caret-down': 'fa-minus font-10'}} {{((element.wcMedioTerm > element.wc && i < 2) || (element.wcMedioTerm < element.wc && i > 2) || (i == 7 && element.wcMedioTerm == 0)) ? 'verde': element.wcMedioTerm == element.wc ? 'nero':'rosso'}}"></i> </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="WCLT">
            <th mat-header-cell *matHeaderCellDef class="wc"> </th>
            <td mat-cell *matCellDef="let element; let i = index"> {{element.wcLungoTerm != 0 ? (element.wcLungoTerm | number:'1.0-0':'it') :
                '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="TR_WCLT">
            <th mat-header-cell *matHeaderCellDef class="wc trend" style="border-right: 1px solid #cccccc;"> </th>
            <td mat-cell *matCellDef="let element; let i = index" class="trend" style="border-right: 1px solid #cccccc;"> <i 
                class="font-15 fa {{element.wcLungoTerm > element.wcMedioTerm? 'fa-caret-up': element.wcLungoTerm < element.wcMedioTerm ? 'fa-caret-down': 'fa-minus font-10'}} {{((element.wcLungoTerm > element.wcMedioTerm && i < 2) || (element.wcLungoTerm < element.wcMedioTerm && i > 2) || (i == 7 && element.wcLungoTerm == 0)) ? 'verde': element.wcLungoTerm == element.wcMedioTerm ? 'nero':'rosso'}}"></i> </td>
        </ng-container>

        <ng-container matColumnDef="BCYTD">
            <th mat-header-cell *matHeaderCellDef class="bc">  </th>
            <td mat-cell *matCellDef="let element; let i = index"> {{element.bc != 0 ? (element.bc | number:'1.0-0':'it') :
                '-'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="TR_BCYTD">
            <th mat-header-cell *matHeaderCellDef class="bc trend"> </th>
            <td mat-cell *matCellDef="let element; let i = index" class="trend"> <i 
                class="font-15 fa {{element.bc > element.annoPrec ? 'fa-caret-up': element.bc < element.annoPrec ? 'fa-caret-down': 'fa-minus font-10'}} {{((element.bc > element.annoPrec && i < 2) || (element.bc < element.annoPrec && i > 2) ||(i == 7 && element.bc == 0)) ? 'verde': element.bc == element.annoPrec ? 'nero':'rosso'}}"></i> </td>
        </ng-container>

        
        <ng-container matColumnDef="BCMT">
            <th mat-header-cell *matHeaderCellDef class="bc"> </th>
            <td mat-cell *matCellDef="let element; let i = index"> {{element.bcMedioTerm != 0 ? (element.bcMedioTerm | number:'1.0-0':'it') :
                '-'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="TR_BCMT">
            <th mat-header-cell *matHeaderCellDef class="bc trend"> </th>
            <td mat-cell *matCellDef="let element; let i = index" class="trend"> <i 
                class="font-15 fa {{element.bcMedioTerm > element.bc? 'fa-caret-up': element.bcMedioTerm < element.bc ? 'fa-caret-down': 'fa-minus font-10'}} {{ ((element.bcMedioTerm > element.bc && i < 2) || (element.bcMedioTerm < element.bc && i > 2) || (i == 7 && element.bcMedioTerm == 0)) ? 'verde': element.bcMedioTerm == element.bc ? 'nero':'rosso'}}"></i> </td>
        </ng-container>

        <ng-container matColumnDef="BCLT">
            <th mat-header-cell *matHeaderCellDef class="bc"> </th>
            <td mat-cell *matCellDef="let element; let i = index"> {{element.bcLungoTerm != 0 ? (element.bcLungoTerm | number:'1.0-0':'it') :
                '-'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="TR_BCLT">
            <th mat-header-cell *matHeaderCellDef class="bc trend"> </th>
            <td mat-cell *matCellDef="let element; let i = index" class="trend"> <i 
                class="font-15 fa {{element.bcLungoTerm > element.bcMedioTerm? 'fa-caret-up': element.bcLungoTerm < element.bcMedioTerm ? 'fa-caret-down': 'fa-minus font-10'}} {{((element.bcLungoTerm > element.bcMedioTerm && i < 2) || (element.bcLungoTerm < element.bcMedioTerm && i > 2) || (i == 7 && element.bcLungoTerm == 0)) ? 'verde': element.bcLungoTerm == element.bcMedioTerm ? 'nero':'rosso'}}"></i> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef=" displayedColumnsCenter"></tr>
        <tr mat-row *matRowDef="let row; columns:  displayedColumnsCenter;"></tr>
    </table>
</div>

<!-- modale -->
<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header" style="justify-content: center !important;">
        <div class="modal-title" style="font-weight: bold;font-size: 15px;text-align: center;
                                        font-family: var(--UI-font);color: black" id="modal-basic-title">BUSINESS PLAN PI&Ugrave; CRITICI </div>
    </div>
    <div class="modal-body">
<div class="row">
    <div class="col-12">
        <table mat-table [dataSource]="matriceWorst"
        class="mat-elevation-z8 mx-auto">

        <!--- Note that these columns can be defined in any order.
                                                The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="descrizione">
            <th mat-header-cell *matHeaderCellDef style="border-right: 1px solid #cccccc;">INDICATORI</th>
            <td mat-cell *matCellDef="let element" style="border-right: 1px solid #cccccc;text-align: left;padding-left: 24px;font-size:12px"> {{element.descrizione }} </td>
        </ng-container>

        <ng-container matColumnDef="ytd">
            <th mat-header-cell *matHeaderCellDef style="border-right: 1px solid #cccccc;">{{this.durataBP.annoStart > 0 ? this.durataBP.annoStart : ''}} </th>
            <td mat-cell *matCellDef="let element" style="border-right: 1px solid #cccccc;font-size:12px" > {{this.durataBP.annoStart > 0 ? element.ytd !=
                'NaN' ? element.ytd : 'N/A':
                ''}}
            </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="mt">
            <th mat-header-cell *matHeaderCellDef style="border-right: 1px solid #cccccc;">MT </th>
            <td mat-cell *matCellDef="let element" style="border-right: 1px solid #cccccc;font-size:12px"> {{element.mt != 'NaN' ? element.mt : 'N/A'}}
            </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="lt">
            <th mat-header-cell *matHeaderCellDef>LT </th>
            <td mat-cell *matCellDef="let element" style="font-size:12px"> {{element.lt != 'NaN' ? element.lt : 'N/A' }}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColWorst"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColWorst;"></tr>
    </table>
    </div>
</div>
<div style="color:black">
    <p>LEGENDA: <strong>A</strong> NET PROFIT NEGATIVO, <strong>B</strong>  PFN/EBITDA FUORI SOGLIA, <strong>C</strong> DEFAULT DI CASSA E/O PATRIMONIO</p>
</div>
    </div>
  </ng-template>
