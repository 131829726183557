import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { delay } from 'rxjs/operators';

import { LoadingService } from '../loading.service';

import { faCaretDown, faCaretUp, faInfo } from '@fortawesome/free-solid-svg-icons';
import { MenuPulsantieraService } from '../header/services/menu-pulsantiera.service';
import { PraticheNuova } from '../interface/Pratiche-nuova';
import { QuestionarioBp } from '../interface/questionario-bp';
import { ConfigurazioneBusinessPlanService } from '../_services/configurazione-business-plan.service';
import { DettaglioRisultatiService } from '../_services/dettaglio-risultati.service';
import { SplashpageServiceService } from '../_services/splashpage-service.service';
import { DurataBP } from './interface/durata-bp';
import { Subscription } from 'rxjs';
import { QuestionarioBpTot } from '../interface/QuestionarioBpTot';
import { SintesiTopSection } from './interface/sintesi-top-section';
import { SmartBpService } from '../_services/smart-bp.service';


enum PositionColum {
  Prima = 0,
  Seconda,
  Terza,
  Quarta,
  Quinta
}


@Component({
  selector: 'qs-risultati-questionario',
  templateUrl: './risultati-questionario.component.html',
  styleUrls: ['./risultati-questionario.component.css']
})

export class RisultatiQuestionarioComponent implements OnInit, OnDestroy {



  volatileData: boolean = false;
  salvato: boolean = false;
  durataBP: DurataBP = {
    annoStart: 0,
    durataAnni: 0
  };

  risultatiVolatileSubscription: Subscription;
  questionarioBpSubscription: Subscription = null;
  bpSubscription: Subscription
  smartBpSubscription: Subscription;
  navigaDettaglioSubscription: Subscription
  navigaDossierSubscription: Subscription
  navigaSintesiSubscription: Subscription
  faCaretUp = faCaretUp;
  faCaretDown = faCaretDown;
  faInfo = faInfo;
  submitted = false;
  annoCorrente = (new Date()).getFullYear();
  isLoading: boolean = false;
  questionario: QuestionarioBp;
  idBusinessPlan = 0;
  praticaBp: PraticheNuova;
  definitivo = false;
  active = 'top';
  dossier: boolean = false;
  dettaglio: boolean = false;
  sintesi: boolean = false;
  commentoExp: string = '';
  smartBp = false;
  constructor(private serviceBp: ConfigurazioneBusinessPlanService, private router: Router, private formBuilder: UntypedFormBuilder,
    private loading: LoadingService, private modalService: NgbModal, private pulsantieraHeader: MenuPulsantieraService,
    private splashPageService: SplashpageServiceService, private detServiceView: DettaglioRisultatiService, private dettaglioRisultatiService: DettaglioRisultatiService,
    private smartBpService: SmartBpService) {
  }




  //START ngOnInit()
  ngOnInit() {


    //this.dettaglioRisultatiService.sendAnnoStart(this.durataBP.annoStart);

    this.risultatiVolatileSubscription = this.serviceBp.currentRisultatiVolatile.subscribe(isVolatile => {
      this.volatileData = isVolatile;
    })
    //richiama funzione di caricamento
    this.listenToLoading();
    /*
        this.pulsantieraHeader._change_state_to_save$.subscribe(
          saved => {
            this.salvato = saved;
          }
        )*/

    this.bpSubscription = this.serviceBp.currentBusinessPlan.subscribe(bp => {
      if (bp != null) {
        this.praticaBp = bp
        this.idBusinessPlan = bp.id;
        this.splashPageService.sendBpId(this.idBusinessPlan);

      }else{
        this.praticaBp = {
          id: 0
        }
      }
    })

    this.smartBpSubscription = this.smartBpService.currentRichiedenteSmartBp.subscribe(richiedente => {
      if (richiedente != null && richiedente.id != 0) {
        this.smartBp = true;
        this.praticaBp = {
          id: 0,
          idAziendaRichiedente: richiedente.id,
          dataCreazione: new Date().toLocaleDateString('en-CA'),

        }
        this.smartBpService.elaboraSmartBp(richiedente.id, richiedente.annoCostituzione, richiedente.investimenti).subscribe(data => {
        
          this.splashPageService.takeValueSplashpage(
            data.splashPageAsIs.indici_anno_corrente.concat(
              data.splashPageAsIs.conto_economico,
              data.splashPageAsIs.cash_flow,
              data.splashPageAsIs.stato_patrimoniale),
            data.splashPageComplessiva.indici_end_stroke.concat(
              data.splashPageComplessiva.media_annua_propspetticca_conto_economico,
              data.splashPageComplessiva.media_annua_propspetticca_cash_flow,
              data.splashPageComplessiva.propspetticca_stato_patrimoniale_end_stroke,
              data.splashPageComplessiva.matriceWorst),
            data.splashPageQuadroSintetico.topTable.rtt, data.splashPageSortino, data.splashPagePicchi, data.listaSplashPageAnnuali);

          this.durataBP.annoStart = data.annoInizio;
          this.dettaglioRisultatiService.sendAnnoStart(this.durataBP.annoStart);
          this.durataBP.durataAnni = data.durata;
          this.splashPageService.sendDurataBP(this.durataBP);

          this.splashPageService.takeSplashYearsListData(data.listaSplashPageAnnuali);
          this.serviceBp.inviaAnnoBilancio(data.annoInizio-1);
          let annoSpaceTosalve: any = [];
          var startData = data.annoInizio;
          data.listaSplashPageAnnuali.forEach(annoData => {
            annoSpaceTosalve.push({
              anno: startData,
              indici_end_stroke: annoData.indici_end_stroke,
              media_annua_propspetticca_conto_economico: annoData.media_annua_propspetticca_conto_economico,
              media_annua_propspetticca_cash_flow: annoData.media_annua_propspetticca_cash_flow,
              propspetticca_stato_patrimoniale_end_stroke: annoData.propspetticca_stato_patrimoniale_end_stroke
            });
          });
          this.dettaglioRisultatiService.aggiornaCommento(data.assunzioni.testoAssunzioni);
//Passiamo l'idrichiedente al dettaglio come idBp per lo smartbp, serve per mostrare il contesto di mercato
          this.detServiceView.takeValueDettaglio({
            idBusinessPlan: richiedente.id,
            annoInizio: data.annoInizio,
            numeroSimulazioni: data.numeroSimulazioni,
            complessiva: data.tabComplessiva,
            indicatori: data.indicatori,
            debitoria: data.situazioneDebitoria,
            contoeconomico: data.tabContoEconomico,
            cashflow: data.tabCashFlow,
            patrimoniale: data.tabStatoPatrimoniale,
            debitoFinanziario: data.debitoFinanziario,
            mcc: data.mccProspettico,
            garanzie: data.garanzie,
            indiciBilancio: data.indiciBilancio,
            istogrammaBilancio: data.istogrammaBilancio,
            crisiImpresa: data.crisiImpresa,
            backtesting: data.backtesting,
            commento: data.assunzioni.testoAssunzioni,
            assunzioni: data.assunzioni
          })

          this.pulsantieraHeader.invioDatiAlButtonSaveDettaglio({
            idBusinessPlan: this.idBusinessPlan,
            idLineeBusinessBp: [],
            annoInizio: data.annoInizio,
            numeroSimulazioni: data.numeroSimulazioni,
            complessiva: data.tabComplessiva,
            indicatori: data.indicatori,
            debitoria: data.situazioneDebitoria,
            contoeconomico: data.tabContoEconomico,
            cashflow: data.tabCashFlow,
            patrimoniale: data.tabStatoPatrimoniale,
            debitoFinanziario: data.debitoFinanziario,
            mcc: data.mccProspettico,
            garanzie: data.garanzie,
            indiciBilancio: data.indiciBilancio,
            istogrammaBilancio: data.istogrammaBilancio,
            crisiImpresa: data.crisiImpresa,
            backtesting: data.backtesting,
            commento: data.assunzioni.testoAssunzioni
          });//aggiornare save  

        })

        

      } else {
        this.smartBp = false;

      }
    })


    if (this.volatileData) {
      this.questionarioBpSubscription = this.serviceBp.currentQuestionarioBp.subscribe(response => {
        if (response != null) {
          this.questionario = response;

        } else {
          if(!this.smartBp) this.router.navigate(['/funzioni']);

        }

      })
      if (this.questionario != null && (this.volatileData)) {//|| this.salvato
        let request: QuestionarioBpTot = {
          questionarioCompleto: this.questionario,
          riferimentoBp: this.praticaBp
        }
        this.serviceBp.calcolaBusinessPlan(request).subscribe(data => {
          //this.risultatiService.takeValueDettaglio({ risultati: data.risultati, boolAziendaEsistente: this.boolAziendaEsistente });

          this.serviceBp.changeBpSalvato(false);
          this.splashPageService.takeValueSplashpage(
            data.splashPageAsIs.indici_anno_corrente.concat(
              data.splashPageAsIs.conto_economico,
              data.splashPageAsIs.cash_flow,
              data.splashPageAsIs.stato_patrimoniale),
            data.splashPageComplessiva.indici_end_stroke.concat(
              data.splashPageComplessiva.media_annua_propspetticca_conto_economico,
              data.splashPageComplessiva.media_annua_propspetticca_cash_flow,
              data.splashPageComplessiva.propspetticca_stato_patrimoniale_end_stroke),
            data.splashPageQuadroSintetico.topTable.rtt, data.splashPageSortino, data.splashPagePicchi, data.listaSplashPageAnnuali);

          this.durataBP.annoStart = data.annoInizio;
          this.dettaglioRisultatiService.sendAnnoStart(this.durataBP.annoStart);
          this.durataBP.durataAnni = data.durata;
          this.splashPageService.sendDurataBP(this.durataBP);

          this.splashPageService.takeSplashYearsListData(data.listaSplashPageAnnuali);

          let annoSpaceTosalve: any = [];
          var startData = data.annoInizio;
          data.listaSplashPageAnnuali.forEach(annoData => {
            annoSpaceTosalve.push({
              anno: startData,
              indici_end_stroke: annoData.indici_end_stroke,
              media_annua_propspetticca_conto_economico: annoData.media_annua_propspetticca_conto_economico,
              media_annua_propspetticca_cash_flow: annoData.media_annua_propspetticca_cash_flow,
              propspetticca_stato_patrimoniale_end_stroke: annoData.propspetticca_stato_patrimoniale_end_stroke
            });
          });

          this.pulsantieraHeader.invioDatiAlButtonSave(
            {
              idBusinessPlan: this.idBusinessPlan,
              sintesi: {
                topTable: data.splashPageQuadroSintetico.topTable,
                valoreFaccina: 'string',
                valutazioneDescrittiva: 'string'
              },
              asis: {
                indici_anno_corrente: data.splashPageAsIs.indici_anno_corrente,
                conto_economico: data.splashPageAsIs.conto_economico,
                cash_flow: data.splashPageAsIs.cash_flow,
                stato_patrimoniale: data.splashPageAsIs.stato_patrimoniale
              },
              complessiva: {
                indici_end_stroke: data.splashPageComplessiva.indici_end_stroke,
                media_annua_propspetticca_conto_economico: data.splashPageComplessiva.media_annua_propspetticca_conto_economico,
                media_annua_propspetticca_cash_flow: data.splashPageComplessiva.media_annua_propspetticca_cash_flow,
                propspetticca_stato_patrimoniale_end_stroke: data.splashPageComplessiva.propspetticca_stato_patrimoniale_end_stroke,

              },
              anno: annoSpaceTosalve,
              picchi: data.splashPagePicchi,

            }
          );

          this.pulsantieraHeader.invioDatiAlButtonSaveDettaglio({
            idBusinessPlan: this.idBusinessPlan,
            annoInizio: data.annoInizio,
            numeroSimulazioni: data.numeroSimulazioni,
            idLineeBusinessBp: [],
            complessiva: data.tabComplessiva,
            indicatori: data.indicatori,
            debitoria: data.situazioneDebitoria,
            contoeconomico: data.tabContoEconomico,
            cashflow: data.tabCashFlow,
            patrimoniale: data.tabStatoPatrimoniale,
            debitoFinanziario: data.debitoFinanziario,
            mcc: data.mccProspettico,
            garanzie: data.garanzie,
            indiciBilancio: data.indiciBilancio,
            istogrammaBilancio: data.istogrammaBilancio,
            crisiImpresa: data.crisiImpresa,
            backtesting: data.backtesting,
            commento: ''
          });//aggiornare save  

          this.detServiceView.takeValueDettaglio({
            idBusinessPlan: this.idBusinessPlan,
            annoInizio: data.annoInizio,
            numeroSimulazioni: data.numeroSimulazioni,
            nomiLdb: data.nomiLdb,
            complessiva: data.tabComplessiva,
            indicatori: data.indicatori,
            debitoria: data.situazioneDebitoria,
            contoeconomico: data.tabContoEconomico,
            cashflow: data.tabCashFlow,
            patrimoniale: data.tabStatoPatrimoniale,
            debitoFinanziario: data.debitoFinanziario,
            mcc: data.mccProspettico,
            garanzie: data.garanzie,
            indiciBilancio: data.indiciBilancio,
            istogrammaBilancio: data.istogrammaBilancio,
            crisiImpresa: data.crisiImpresa,
            backtesting: data.backtesting,
            commento: ''
          })
        });
      }
    } else {
      if (this.idBusinessPlan != 0) {

        /*   METODO PER RIEMPIMENTO TEXT*/
        this.dettaglioRisultatiService.getCommentoBp(this.idBusinessPlan).subscribe((comm: string) => {
          if (comm != null) {
            this.commentoExp = comm;
            this.dettaglioRisultatiService.aggiornaCommento(comm);
          }
        })


        this.splashPageService.postDurataBP(this.idBusinessPlan).subscribe(durata => {
          this.durataBP = durata;
          this.dettaglioRisultatiService.sendAnnoStart(this.durataBP.annoStart);
          this.splashPageService.sendDurataBP(this.durataBP);
        });
      } else {
        if(!this.smartBp) this.router.navigate(['/funzioni'])
      }
    }

    this.navigaDettaglioSubscription = this.pulsantieraHeader.NAVIGATE_DETTAGLIO$.subscribe(dettaglio => {
      this.dettaglio = dettaglio;
    })

    this.navigaDossierSubscription = this.pulsantieraHeader.NAVIGATE_DOSSIER$.subscribe(dossier => {
      this.dossier = dossier;
    })

    this.navigaSintesiSubscription = this.pulsantieraHeader.NAVIGATE_SINTESI$.subscribe(sintesi => {
      this.sintesi = sintesi;
    })
  }
  //END ngOnInit()
  valueAfterChecked = true;
  timeValueCheck = 1;

  ngAfterContentChecked() {

  }

  /**
  * Listen to the loadingSub property in the LoadingService class. This drives the
  * display of the loading spinner.
  */
  listenToLoading(): void {
    this.loading.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.isLoading = loading;
      }).unsubscribe();
  }

  goBack() {
    if (this.definitivo) {
      this.router.navigate(["/pratiche"]);
    } else {
      this.router.navigate(["/questionario"]);
    }
  }


  tornaIndietro(): void {
    this.modalService.dismissAll();
    this.router.navigate(["/pratiche"]);
  }

  ngOnDestroy(): void {
    this.risultatiVolatileSubscription.unsubscribe();
    if (this.questionarioBpSubscription && this.questionarioBpSubscription !=  null) {
      this.questionarioBpSubscription.unsubscribe();
    }
    this.bpSubscription.unsubscribe()
    this.smartBpSubscription.unsubscribe()
    this.navigaDettaglioSubscription.unsubscribe()
    this.navigaDossierSubscription.unsubscribe()
    this.navigaSintesiSubscription.unsubscribe()
  }
}
