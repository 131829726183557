import { AfterViewInit, Component, Input, OnInit, OnDestroy } from '@angular/core';
import { TimelineButtonsNavigatorService } from '../services/timeline-buttons-navigator.service';
import { SplashpageService } from '../services/splashpage.service';
import { SintesiTopSection } from '../interface/sintesi-top-section';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { DettaglioChartService } from '../services/dettaglio-chart.service';
import { SplashpageServiceService } from 'src/app/_services/splashpage-service.service';
import { DatiQuestionarioService } from 'src/app/_services/dati-questionario.service';
import { ConfigurazioneBusinessPlanService } from 'src/app/_services/configurazione-business-plan.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ConfigurazioneAmbienteService } from 'src/app/_services/configurazione-ambiente.service';
import { Subscription } from 'rxjs';
import { DettaglioRisultatiService } from 'src/app/_services/dettaglio-risultati.service';
import { ChartType, ChartData, ChartOptions } from 'chart.js';


@Component({
  selector: 'app-splashpage-risultati',
  templateUrl: './splashpage-risultati.component.html',
  styleUrls: ['./splashpage-risultati.component.css'],
  animations: [
    trigger('zoomChartDettaglioState', [
      state('unzoom', style({
        opacity: 0
      })),
      state('zoom', style({
        opacity: 1
      })),
      transition('zoom => unzoom', [
        animate('1s'),
      ]),
      transition('unzoom => zoom', [
        animate('1s'),
      ])
    ])]
})

export class SplashpageRisultatiComponent implements OnInit, OnDestroy {

  @Input() valueOrdinataNegativo: number[] = [];
  @Input() valueOrdinataMedia: number[] = [];
  @Input() valueOrdinataPositivo: number[] = [];
  @Input() nomeAzienda: string = "nome azienda";
  @Input() smartBp: boolean;

  isPersistent: boolean = false;
  idBplan: number;
  annoInizioBilancio = 0;

  ELEMENT_AS_IS_QUADROSINTETICO: SintesiTopSection[] = [
    { tipoQuadroSintetico: "DSCR", ytd: '', plusone_yd: '', mt: '', lt: '' },
    { tipoQuadroSintetico: "PFN/EBITDA", ytd: '', plusone_yd: '', mt: '', lt: '' },
    { tipoQuadroSintetico: "LEVERAGE", ytd: '', plusone_yd: '', mt: '', lt: '' },
    { tipoQuadroSintetico: "EBITDA MARGIN", ytd: '', plusone_yd: '', mt: '', lt: '' },
    { tipoQuadroSintetico: "MCC (% INADEMPIMENTO)", ytd: '', plusone_yd: '', mt: '', lt: '' },
    { tipoQuadroSintetico: "ROE", ytd: '', plusone_yd: '', mt: '', lt: '' },

  ];

  displayedColumns: string[] = ['Quadro sintetico','PRECEDENTE', 'YTD', '+1YD', 'MT', 'LT'];
  dataSourcePerditaSortino = this.ELEMENT_AS_IS_QUADROSINTETICO;

  is_as: boolean = true;
  select_year: number = 0;
  stateZoom = 'unzoom';
  stateZoomInverse = 'zoom';
  valoreMax: any = {};
  risultatiVolatileSubscription: Subscription
  zoomSuscription: Subscription
  unzoomSuscription: Subscription
  asisSuscription: Subscription
  yearSuscription: Subscription
  topSectionSubscription: Subscription
  idBpSubscription: Subscription
  annoBilancioSubscription: Subscription
  commentoSubscription: Subscription;
  risultatiDettaglioSubscription: Subscription
  commentoExp:string = '';
  risultatiDettaglio: any = null;
   public barChartType: ChartType = 'bar';
    barChartDataPfnEbitda: ChartData;
    barChartDataContoEcon: ChartData;
    barChartDataPatrDscr: ChartData;
    barChartDataInvest: ChartData;
    public barChartOptions: ChartOptions = {
      responsive: true,
      scales: {
        x: {},
        y: {
          type: 'linear',
          position: 'left',
          title: {
            display: true,
            text: 'K€'
          }
        },
        y1: {
          type: 'linear',
          position: 'right',
          title: {
            display: true,
            text: 'PFN/Ebitda'
          },
          grid: {
            drawOnChartArea: false, // Prevent grid lines from overlapping
          }
        },
      },
      plugins: {
        legend: {
          display: true
        }
      }
    };
    public barChartOptions2: ChartOptions = {
      responsive: true,
      scales: {
        x: {},
        y: {
          type: 'linear',
          position: 'left',
          title: {
            display: true,
            text: 'K€'
          }
        },
        y2: {
          type: 'linear',
          position: 'right',
          title: {
            display: true,
            text: 'K€'
          },
          grid: {
            drawOnChartArea: false, // Prevent grid lines from overlapping
          }
        }
      },
      plugins: {
        legend: {
          display: true
        }
      }
    };
    public barChartOptions3: ChartOptions = {
      responsive: true,
      scales: {
        x: {},
        y: {
          type: 'linear',
          position: 'left',
          title: {
            display: true,
            text: 'K€'
          }
        },
        y3: {
          type: 'linear',
          position: 'right',
          title: {
            display: true,
            text: 'DSCR'
          },
          grid: {
            drawOnChartArea: false, // Prevent grid lines from overlapping
          }
        }
      },
      plugins: {
        legend: {
          display: true
        }
      }
    };
    public barChartOptions4: ChartOptions = {
      responsive: true,
      scales: {
        x: {},
        y: {
          type: 'linear',
          position: 'left',
          title: {
            display: true,
            text: 'K€'
          }
        },
      },
      plugins: {
        legend: {
          display: true
        }
      }
    };
  constructor(private datiQuestionarioService: DatiQuestionarioService, private timelineButtonsNavigatorService: TimelineButtonsNavigatorService,
    private splashpageService: SplashpageService, private dettaglioChartService: DettaglioChartService, private splash_service: SplashpageServiceService,
    private serviceBp: ConfigurazioneBusinessPlanService,
    private configurazioneAmbienteService: ConfigurazioneAmbienteService,
    private dettagliRisultati: DettaglioRisultatiService) { }

  ngOnDestroy(): void {
    this.risultatiVolatileSubscription.unsubscribe()
    this.idBpSubscription.unsubscribe()
    this.zoomSuscription.unsubscribe()
    this.unzoomSuscription.unsubscribe()
    this.asisSuscription.unsubscribe()
    this.yearSuscription.unsubscribe()
    this.commentoSubscription.unsubscribe();
    if (!this.isPersistent) {
      this.topSectionSubscription.unsubscribe()
      this.annoBilancioSubscription.unsubscribe()
      this.risultatiDettaglioSubscription.unsubscribe();
    }
  }
  ngOnInit(): void {

   this.commentoSubscription =  this.dettagliRisultati.risultatiCommento$.subscribe(comm => {
      if(comm != null ) this.commentoExp = comm;
    })

    this.idBpSubscription = this.splash_service.BpId$.subscribe(
      id => {
        this.idBplan = id;

      }
    )
    /*
        this.datiQuestionarioService.checkFormTouched.subscribe(isVolatile => {
    
          this.isPersistent = !isVolatile;
    
        })
        */

    this.risultatiVolatileSubscription = this.serviceBp.currentRisultatiVolatile.subscribe(isVolatile => {
      this.isPersistent = !isVolatile;
    })



    this.unzoomSuscription = this.dettaglioChartService.UnZoom$.subscribe(
      unzoom => {
        this.stateZoom = unzoom;
        this.stateZoomInverse = 'zoom'
      }
    )

    this.zoomSuscription = this.dettaglioChartService.Zoom$.subscribe(
      zoom => {
        this.stateZoom = zoom;
        this.stateZoomInverse = 'unzoom'
      }
    )

    this.asisSuscription = this.timelineButtonsNavigatorService.AS_IS$.subscribe(is_as => {
      this.is_as = is_as;
    })

    this.yearSuscription = this.timelineButtonsNavigatorService.YEARS$.subscribe(year => {
      this.select_year = year;
    })

    if (this.isPersistent) {

      if (this.idBplan != 0) {

        this.splash_service.postGetAnnoBilancio(this.idBplan).subscribe(startInizioBilancio => {
          if (startInizioBilancio != null) {
            this.annoInizioBilancio = startInizioBilancio;
          }
        });

        this.splashpageService.postAsIsData(this.idBplan).subscribe(sintesiTopSection => {
          let valoriTopSection = sintesiTopSection;

          this.ELEMENT_AS_IS_QUADROSINTETICO = [];
          for (let i = 0; i < valoriTopSection.topTable.rtt.length - 1; i++) {
            this.ELEMENT_AS_IS_QUADROSINTETICO.push({
              tipoQuadroSintetico: valoriTopSection.topTable.rtt[i].tipoQuadroSintetico,
              prec: valoriTopSection.topTable.rtt[i].annoPrecString,
              ytd: valoriTopSection.topTable.rtt[i].ytdString,
              plusone_yd: valoriTopSection.topTable.rtt[i].plusone_ydString,
              mt: valoriTopSection.topTable.rtt[i].mtString,
              lt: valoriTopSection.topTable.rtt[i].ltString
            });
          }

          if(valoriTopSection.topTable.rtt[9].plusone_yd != 0. || valoriTopSection.topTable.rtt[9].mt != 0. || valoriTopSection.topTable.rtt[9].lt != 0.){
            this.ELEMENT_AS_IS_QUADROSINTETICO.push(            {
              tipoQuadroSintetico: valoriTopSection.topTable.rtt[9].tipoQuadroSintetico,
              prec: valoriTopSection.topTable.rtt[9].annoPrecString,
              ytd: valoriTopSection.topTable.rtt[9].ytdString,
              plusone_yd: valoriTopSection.topTable.rtt[9].plusone_ydString,
              mt: valoriTopSection.topTable.rtt[9].mtString,
              lt: valoriTopSection.topTable.rtt[9].ltString
            });
          }


          this.configurazioneAmbienteService.getValoriMax('risultati').subscribe(
            valMax => {
              valMax.forEach(element => {
                if (element.error == null) {
                  this.valoreMax[element.nomeCampo] = element.valoreMax;
                }
              });
              if (valoriTopSection.topTable.rtt[4].ytd > this.valoreMax['dscr']) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[4].ytd = '> ' + this.valoreMax['dscr'];
              }
              else if (valoriTopSection.topTable.rtt[4].ytd < this.valoreMax['dscr'] * (-1)) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[4].ytd = '< ' + this.valoreMax['dscr'] * (-1);
              }
              if (valoriTopSection.topTable.rtt[4].plusone_yd > this.valoreMax['dscr']) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[4].plusone_yd = '> ' + this.valoreMax['dscr'];
              }
              else if (valoriTopSection.topTable.rtt[4].plusone_yd < this.valoreMax['dscr'] * (-1)) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[4].plusone_yd = '< ' + this.valoreMax['dscr'] * (-1);
              }
              if (valoriTopSection.topTable.rtt[4].mt > this.valoreMax['dscr']) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[4].mt = '> ' + this.valoreMax['dscr'];
              }
              else if (valoriTopSection.topTable.rtt[4].mt < this.valoreMax['dscr'] * (-1)) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[4].mt = '< ' + this.valoreMax['dscr'] * (-1);
              }
              if (valoriTopSection.topTable.rtt[4].lt > this.valoreMax['dscr']) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[4].lt = '> ' + this.valoreMax['dscr'];
              }
              else if (valoriTopSection.topTable.rtt[4].lt < this.valoreMax['dscr'] * (-1)) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[4].lt = '< ' + this.valoreMax['dscr'] * (-1);
              }

              this.dataSourcePerditaSortino = this.ELEMENT_AS_IS_QUADROSINTETICO;
            }
          );



        })
      }
    } else {
      this.topSectionSubscription = this.splash_service.topSection$.subscribe(dataTopSection => {
        if (dataTopSection != null) {
          this.ELEMENT_AS_IS_QUADROSINTETICO = [
            {
              tipoQuadroSintetico: dataTopSection[0].tipoQuadroSintetico,
              prec: dataTopSection[0].annoPrecString,
              ytd: dataTopSection[0].ytdString,
              plusone_yd: dataTopSection[0].plusone_ydString,
              mt: dataTopSection[0].mtString,
              lt: dataTopSection[0].ltString
            },
            {
              tipoQuadroSintetico: dataTopSection[1].tipoQuadroSintetico,
              prec: dataTopSection[1].annoPrecString,
              ytd: dataTopSection[1].ytdString,
              plusone_yd: dataTopSection[1].plusone_ydString,
              mt: dataTopSection[1].mtString,
              lt: dataTopSection[1].ltString
            },
            {
              tipoQuadroSintetico: dataTopSection[2].tipoQuadroSintetico,
              prec: dataTopSection[2].annoPrecString,
              ytd: dataTopSection[2].ytdString,
              plusone_yd: dataTopSection[2].plusone_ydString,
              mt: dataTopSection[2].mtString,
              lt: dataTopSection[2].ltString
            },
            {
              tipoQuadroSintetico: dataTopSection[3].tipoQuadroSintetico,
              prec: dataTopSection[3].annoPrecString,
              ytd: dataTopSection[3].ytdString,
              plusone_yd: dataTopSection[3].plusone_ydString,
              mt: dataTopSection[3].mtString,
              lt: dataTopSection[3].ltString
            },
            {
              tipoQuadroSintetico: dataTopSection[4].tipoQuadroSintetico,
              prec: dataTopSection[4].annoPrecString,
              ytd: dataTopSection[4].ytdString,
              plusone_yd: dataTopSection[4].plusone_ydString,
              mt: dataTopSection[4].mtString,
              lt: dataTopSection[4].ltString
            },
            {
              tipoQuadroSintetico: dataTopSection[5].tipoQuadroSintetico,
              prec: dataTopSection[5].annoPrecString,
              ytd: dataTopSection[5].ytdString,
              plusone_yd: dataTopSection[5].plusone_ydString,
              mt: dataTopSection[5].mtString,
              lt: dataTopSection[5].ltString
            },
            {
              tipoQuadroSintetico: dataTopSection[6].tipoQuadroSintetico,
              prec: dataTopSection[6].annoPrecString,
              ytd: dataTopSection[6].ytdString,
              plusone_yd: dataTopSection[6].plusone_ydString,
              mt: dataTopSection[6].mtString,
              lt: dataTopSection[6].ltString
            },            {
              tipoQuadroSintetico: dataTopSection[7].tipoQuadroSintetico,
              prec: dataTopSection[7].annoPrecString,
              ytd: dataTopSection[7].ytdString,
              plusone_yd: dataTopSection[7].plusone_ydString,
              mt: dataTopSection[7].mtString,
              lt: dataTopSection[7].ltString
            },            {
              tipoQuadroSintetico: dataTopSection[8].tipoQuadroSintetico,
              prec: dataTopSection[8].annoPrecString,
              ytd: dataTopSection[8].ytdString,
              plusone_yd: dataTopSection[8].plusone_ydString,
              mt: dataTopSection[8].mtString,
              lt: dataTopSection[8].ltString
            },
          ];

          if(dataTopSection[9].plusone_yd != 0. || dataTopSection[9].mt != 0. || dataTopSection[9].lt != 0.){
            this.ELEMENT_AS_IS_QUADROSINTETICO.push(            {
              tipoQuadroSintetico: dataTopSection[9].tipoQuadroSintetico,
              prec: dataTopSection[9].annoPrecString,
              ytd: dataTopSection[9].ytdString,
              plusone_yd: dataTopSection[9].plusone_ydString,
              mt: dataTopSection[9].mtString,
              lt: dataTopSection[9].ltString
            });
          }
          this.configurazioneAmbienteService.getValoriMax('risultati').subscribe(
            valMax => {
              valMax.forEach(element => {
                if (element.error == null) {
                  this.valoreMax[element.nomeCampo] = element.valoreMax;
                }
              });
              if (dataTopSection[4].annoPrec > this.valoreMax['dscr']) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[4].prec = '> ' + this.valoreMax['dscr'];
              }
              else if (dataTopSection[4].ytd < this.valoreMax['dscr'] * (-1)) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[4].ytd = '< ' + this.valoreMax['dscr'] * (-1);
              }
              if (dataTopSection[4].ytd > this.valoreMax['dscr']) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[4].ytd = '> ' + this.valoreMax['dscr'];
              }
              else if (dataTopSection[4].ytd < this.valoreMax['dscr'] * (-1)) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[4].ytd = '< ' + this.valoreMax['dscr'] * (-1);
              }
              if (dataTopSection[4].plusone_yd > this.valoreMax['dscr']) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[4].plusone_yd = '> ' + this.valoreMax['dscr'];
              }
              else if (dataTopSection[4].plusone_yd < this.valoreMax['dscr'] * (-1)) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[4].plusone_yd = '< ' + this.valoreMax['dscr'] * (-1);
              }
              if (dataTopSection[4].mt > this.valoreMax['dscr']) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[4].mt = '> ' + this.valoreMax['dscr'];
              }
              else if (dataTopSection[4].mt < this.valoreMax['dscr'] * (-1)) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[4].mt = '< ' + this.valoreMax['dscr'] * (-1);
              }
              if (dataTopSection[4].lt > this.valoreMax['dscr']) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[4].lt = '> ' + this.valoreMax['dscr'];
              }
              else if (dataTopSection[4].lt < this.valoreMax['dscr'] * (-1)) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[4].lt = '< ' + this.valoreMax['dscr'] * (-1);
              }

              this.dataSourcePerditaSortino = this.ELEMENT_AS_IS_QUADROSINTETICO;
            }
          );
        }
      })

      this.annoBilancioSubscription = this.serviceBp._annoBilancio$.subscribe(anno => {
        if (anno != 0) this.annoInizioBilancio = anno;
      })

      this.risultatiDettaglioSubscription = this.dettagliRisultati.risultatiDettaglio$.subscribe(data => {
        if (data != null) {
          this.risultatiDettaglio = {
            idBusinessPlan: 0,
            annoInizio: data.annoInizio,
            numeroSimulazioni: data.numeroSimulazioni,
            nomiLdb: data.nomiLdb,
            complessiva: data.complessiva,
            indicatori: data.indicatori,
            debitoria: data.debitoria,
            contoeconomico: data.contoeconomico,
            cashflow: data.cashflow,
            patrimoniale: data.patrimoniale,
            debitoFinanziario: data.debitoFinanziario,
            mcc: data.mcc,
            garanzie: data.garanzie,
            indiciBilancio: data.indiciBilancio,
            istogrammaBilancio: data.istogrammaBilancio,
            crisiImpresa: data.crisiImpresa,
            commento: ''
          }

          this.barChartDataPfnEbitda = {labels:[data.annoInizio,data.annoInizio + 1, data.annoInizio + 2, data.annoInizio +3, data.annoInizio + 4], datasets: [
            { data: [Math.round(data.indiciBilancio[1].colonna[3].pfnEbitda*100.)/100.,Math.round(data.indiciBilancio[1].colonna[4].pfnEbitda*100.)/100.,Math.round(data.indiciBilancio[1].colonna[5].pfnEbitda*100.)/100.,Math.round(data.indiciBilancio[1].colonna[6].pfnEbitda*100.)/100.,Math.round(data.indiciBilancio[1].colonna[7].pfnEbitda*100.)/100.], label: 'PFN/Ebitda', type: 'line', yAxisID: 'y1', fill: false, borderColor: "#ffff00", backgroundColor: "#ffff00", pointBackgroundColor: "#ffff00", hoverBackgroundColor: ' #ffff00' },
            { data: [Math.round(data.contoeconomico[1].rowContoEconomico[0].ebitda/1000.),Math.round(data.contoeconomico[1].rowContoEconomico[1].ebitda/1000.),Math.round(data.contoeconomico[1].rowContoEconomico[2].ebitda/1000.),Math.round(data.contoeconomico[1].rowContoEconomico[3].ebitda/1000.),Math.round(data.contoeconomico[1].rowContoEconomico[4].ebitda/1000.)], label: 'Ebitda', backgroundColor: '#000099', type: 'bar', yAxisID: 'y', hoverBackgroundColor: ' #000099' },
            { data: [Math.round(data.indiciBilancio[1].colonna[3].pfn/1000.),Math.round(data.indiciBilancio[1].colonna[4].pfn/1000.),Math.round(data.indiciBilancio[1].colonna[5].pfn/1000.),Math.round(data.indiciBilancio[1].colonna[6].pfn/1000.),Math.round(data.indiciBilancio[1].colonna[7].pfn/1000.)], label: 'PFN', type: 'bar', yAxisID: 'y', backgroundColor: '#ff6600', hoverBackgroundColor: ' #ff6600' },
          ]};
        

        this.barChartDataContoEcon = {labels:[data.annoInizio,data.annoInizio + 1, data.annoInizio + 2, data.annoInizio +3, data.annoInizio + 4], datasets: [
          { data: [Math.round(data.contoeconomico[1].rowContoEconomico[0].ebitda/1000.),Math.round(data.contoeconomico[1].rowContoEconomico[1].ebitda/1000.),Math.round(data.contoeconomico[1].rowContoEconomico[2].ebitda/1000.),Math.round(data.contoeconomico[1].rowContoEconomico[3].ebitda/1000.),Math.round(data.contoeconomico[1].rowContoEconomico[4].ebitda/1000.)], label: 'Ebitda', type: 'line', yAxisID: 'y2', fill: false, borderColor: "#000099", backgroundColor: "#000099", pointBackgroundColor: "#000099", hoverBackgroundColor: ' #000099' },
          { data: [Math.round(data.contoeconomico[1].rowContoEconomico[0].netProfit/1000.),Math.round(data.contoeconomico[1].rowContoEconomico[1].netProfit/1000.),Math.round(data.contoeconomico[1].rowContoEconomico[2].netProfit/1000.),Math.round(data.contoeconomico[1].rowContoEconomico[3].netProfit/1000.),Math.round(data.contoeconomico[1].rowContoEconomico[4].netProfit/1000.)], label: 'Net Profit', type: 'line', yAxisID: 'y2', fill: false, backgroundColor: "#3399ff", borderColor: "#3399ff", pointBackgroundColor: "#3399ff", hoverBackgroundColor: ' #3399ff' },
          { data: [Math.round(data.contoeconomico[1].rowContoEconomico[0].ricavi/1000.),Math.round(data.contoeconomico[1].rowContoEconomico[1].ricavi/1000.),Math.round(data.contoeconomico[1].rowContoEconomico[2].ricavi/1000.),Math.round(data.contoeconomico[1].rowContoEconomico[3].ricavi/1000.),Math.round(data.contoeconomico[1].rowContoEconomico[4].ricavi/1000.)], label: 'Valore della produzione',stack:'a', type: 'bar', yAxisID: 'y', backgroundColor: ' #33cc33', hoverBackgroundColor: ' #33cc33' },
          { data: [Math.round((data.contoeconomico[1].rowContoEconomico[0].costi + data.contoeconomico[1].rowContoEconomico[0].costiFatturato)/1000.),Math.round((data.contoeconomico[1].rowContoEconomico[1].costi + data.contoeconomico[1].rowContoEconomico[1].costiFatturato)/1000.),Math.round((data.contoeconomico[1].rowContoEconomico[2].costi + data.contoeconomico[1].rowContoEconomico[2].costiFatturato)/1000.),Math.round((data.contoeconomico[1].rowContoEconomico[3].costi + data.contoeconomico[1].rowContoEconomico[3].costiFatturato)/1000.),Math.round((data.contoeconomico[1].rowContoEconomico[4].costi + data.contoeconomico[1].rowContoEconomico[4].costiFatturato)/1000.)], label: 'Costi di produzione', stack:'b', type: 'bar', yAxisID: 'y', backgroundColor: '#cc0000', hoverBackgroundColor: ' #cc0000' },
          { data: [Math.round(data.contoeconomico[1].rowContoEconomico[0].costiFissi/1000.),Math.round(data.contoeconomico[1].rowContoEconomico[1].costiFissi/1000.),Math.round(data.contoeconomico[1].rowContoEconomico[2].costiFissi/1000.),Math.round(data.contoeconomico[1].rowContoEconomico[3].costiFissi/1000.),Math.round(data.contoeconomico[1].rowContoEconomico[4].costiFissi/1000.)], label: 'Costi fissi', stack:'b', type: 'bar', yAxisID: 'y', backgroundColor: '#990033', hoverBackgroundColor: ' #990033' },
        ]};

        this.barChartDataPatrDscr = {labels:[data.annoInizio,data.annoInizio + 1, data.annoInizio + 2, data.annoInizio +3, data.annoInizio + 4], datasets: [
          { data: [Math.round(data.patrimoniale[1].colonne[0].totDebitiBancari/1000.),Math.round(data.patrimoniale[1].colonne[1].totDebitiBancari/1000.),Math.round(data.patrimoniale[1].colonne[2].totDebitiBancari/1000.),Math.round(data.patrimoniale[1].colonne[3].totDebitiBancari/1000.),Math.round(data.patrimoniale[1].colonne[4].totDebitiBancari/1000.)], label: 'Debito finanziario', type: 'line', yAxisID: 'y', fill: false, borderColor: "#800000", backgroundColor: "#800000", pointBackgroundColor: "#800000",hoverBackgroundColor: ' #800000' },
          { data: [Math.round(data.indiciBilancio[1].colonna[3].dscrAnnuale*100.)/100.,Math.round(data.indiciBilancio[1].colonna[4].dscrAnnuale*100.)/100.,Math.round(data.indiciBilancio[1].colonna[5].dscrAnnuale*100.)/100.,Math.round(data.indiciBilancio[1].colonna[6].dscrAnnuale*100.)/100.,Math.round(data.indiciBilancio[1].colonna[7].dscrAnnuale*100.)/100.], label: 'DSCR', type: 'line', yAxisID: 'y3', fill: false, borderColor: "#ff9933", backgroundColor: "#ff9933", pointBackgroundColor: "#ff9933", hoverBackgroundColor: ' #ff9933' },
          { data: [Math.round(data.patrimoniale[1].colonne[0].patrimonioNetto/1000.),Math.round(data.patrimoniale[1].colonne[1].patrimonioNetto/1000.),Math.round(data.patrimoniale[1].colonne[2].patrimonioNetto/1000.),Math.round(data.patrimoniale[1].colonne[3].patrimonioNetto/1000.),Math.round(data.patrimoniale[1].colonne[4].patrimonioNetto/1000.)], label: 'Patrimonio netto',stack:'a', type: 'bar', yAxisID: 'y', backgroundColor: '#00cc00',hoverBackgroundColor: ' #00cc00' },
        ]};
      }
if(data && data.indiciBilancio && data.indiciBilancio[1].colonna[0].dcfInvest != null && data.indiciBilancio[1].colonna[0].dcfInvest != 0){
      this.barChartDataInvest = {labels:[data.annoInizio,data.annoInizio + 1, data.annoInizio + 2, data.annoInizio +3, data.annoInizio + 4], datasets: [
        { data: [Math.round(data.contoeconomico[1].rowContoEconomico[0].ebitda/1000.- data.indiciBilancio[1].colonna[3].ebitdaInvest/1000.),Math.round(data.contoeconomico[1].rowContoEconomico[1].ebitda/1000.- data.indiciBilancio[1].colonna[4].ebitdaInvest/1000.),Math.round(data.contoeconomico[1].rowContoEconomico[2].ebitda/1000.- data.indiciBilancio[1].colonna[5].ebitdaInvest/1000.),Math.round(data.contoeconomico[1].rowContoEconomico[3].ebitda/1000. - data.indiciBilancio[1].colonna[6].ebitdaInvest/1000.),Math.round(data.contoeconomico[1].rowContoEconomico[4].ebitda/1000. - data.indiciBilancio[1].colonna[7].ebitdaInvest/1000.)], label: 'Ebitda pre investimento',stack:'a', type: 'bar', yAxisID: 'y', backgroundColor: "#000099", hoverBackgroundColor: ' #000099' },
        { data: [Math.round(data.indiciBilancio[1].colonna[3].ebitdaInvest/1000.),Math.round(data.indiciBilancio[1].colonna[4].ebitdaInvest/1000.),Math.round(data.indiciBilancio[1].colonna[5].ebitdaInvest/1000.),Math.round(data.indiciBilancio[1].colonna[6].ebitdaInvest/1000.),Math.round(data.indiciBilancio[1].colonna[7].ebitdaInvest/1000.)], label: 'Ebitda investimento',stack:'a', type: 'bar', yAxisID: 'y', backgroundColor: "#3399ff", hoverBackgroundColor: ' #3399ff' },
      ]};
    } else {
      this.barChartDataInvest = null;
    }
      })
      
    }

  }

  ngAfterContentInit() {

  }

  activateDettaglioChart() {
    this.stateZoom = this.stateZoom == 'zoom' ? 'zoom' : 'unzoom';
  }

  unZoomClick() {
    this.dettaglioChartService.noShowDettaglioChart();
  }

}
